import { ComputedProperties, ComputedProperty } from './ReplicantComputedProperties';
import SB from './SchemaBuilder';

// Mapping between the profile prop roles and the computed prop types they expect.
export const adminProfilePropRoles = {
    name: SB.string(),
    picture: SB.string(),
    friendIds: SB.array(SB.string()),
};

type AdminProfilePropRoles = {
    [K in keyof typeof adminProfilePropRoles]: SB.ExtractType<(typeof adminProfilePropRoles)[K]>;
};

// Given
//   a specific computed properties type which extends { [key]: ComputedProp<..., Schema<ComputedPropType>> },
//   an admin profile prop whose property is among the computed properties,
//   and a reference type,
// this returns
//   the admin profile prop type, if the computed prop type extends the reference type; or
//   `never`, in all other cases.
type ValidAdminProfilePropRolesForComputedProperties<
    TComputedProperties extends ComputedProperties,
    TAdminProfileProp extends { property: unknown },
    TAdminProfilePropRoleType,
> = TAdminProfileProp extends {
    property: infer TComputedPropKey;
}
    ? TComputedPropKey extends keyof TComputedProperties
        ? TComputedProperties[TComputedPropKey] extends ComputedProperty<any, infer TComputedPropSchema>
            ? TComputedPropSchema extends SB.Schema<infer TComputedPropType>
                ? TComputedPropType extends TAdminProfilePropRoleType
                    ? TAdminProfileProp
                    : never
                : never
            : never
        : never
    : never;

// Builds a union of { caption: string, property: ComputedPropKey, role?: AdminProfilePropRole }
// where the role's expected type matches the property's return type.
type ProfileEntryWithPropRole<TComputedProperties extends ComputedProperties> = {
    [K in keyof AdminProfilePropRoles]: ValidAdminProfilePropRolesForComputedProperties<
        TComputedProperties,
        { caption: string; property: keyof TComputedProperties; role?: K },
        AdminProfilePropRoles[K]
    >;
}[keyof AdminProfilePropRoles];

export type AdminToolProfileConfig<TComputedProperties extends ComputedProperties> = (
    | { caption: string; property: keyof TComputedProperties; role?: never }
    | ProfileEntryWithPropRole<TComputedProperties>
)[];
