/* eslint-disable no-prototype-builtins */
import { ReplicantPlatformMessagePayloadEncoderAPI } from './api/ReplicantAPI';
import { AnalyticsPayload } from './common/Analytics';
import { ReplicantError } from './Errors';
import { Actions } from './ReplicantActions';
import { adminProfilePropRoles, AdminToolProfileConfig } from './ReplicantAdminProfileConfig';
import { AsyncGetters } from './ReplicantAsyncGetters';
import { ChatbotAssets, ChatbotConfig, RenderTemplatesWithName } from './ReplicantChatbot';
import { ComputedProperties } from './ReplicantComputedProperties';
import { ComputedPropertyScripts } from './ReplicantComputedPropertyScripts';
import { FilterAdminMessages, Messages } from './ReplicantMessages';
import ReplicantMigrator from './ReplicantMigrator';
import { mergeModuleConfig, ReplicantModule, ReplicantWithModules } from './ReplicantModules';
import { EmptyRuleset, extendRulesetWithDefaults, Ruleset, rulesetSchema } from './ReplicantRuleset';
import { ScheduledActions } from './ReplicantScheduledActions';
import { sharedStateHasSearchableProperties, type SharedStates } from './ReplicantSharedStates';
import SB from './SchemaBuilder';
import { ExtractType } from './SchemaBuilder/Builder';
import { systemStateFieldNames, WithMeta } from './systemStateFields';

export type Replicant<
    TUserState = any,
    TActions extends Actions<Replicant<TUserState>> = Actions<any>,
    TMessages extends Messages<TUserState, TRuleset, TSharedStates> = any,
    TComputedProperties extends ComputedProperties = any,
    TScheduledActions extends ScheduledActions<Replicant<TUserState>> = any,
    TAsyncGetters extends AsyncGetters<TUserState, TRuleset, TComputedProperties, TSharedStates, TModules> = any,
    TChatbotAssets extends ChatbotAssets = any,
    TOnLoginAction extends keyof TActions = any,
    TRuleset extends Ruleset = EmptyRuleset,
    TOtpTemplateId extends string = string,
    TSharedStates extends SharedStates = SharedStates,
    TModules extends ReplicantModule[] = any,
> = {
    state: TUserState;
    actions: TActions;
    messages: TMessages;
    computedProperties: TComputedProperties;
    scheduledActions: TScheduledActions;
    asyncGetters: TAsyncGetters;
    chatbotAssets: TChatbotAssets;
    onLoginAction: TOnLoginAction;
    ruleset: TRuleset;
    otpTemplateId: TOtpTemplateId;
    sharedStates: TSharedStates;
    modules: TModules;
};

export type PartialReplicant<T extends Partial<Replicant>> = Omit<Replicant, keyof T> & T;

export type OnErrorHandler = (error: ReplicantError) => void;

export interface ReplicantConfig<T extends Replicant> {
    stateSchema: SB.Schema<T['state']>;
    actions: T['actions'];
    messages?: T['messages'];

    /**
     * Extra configuration modules created with `createModule`.
     *
     * @see https://docs.dev.gc-internal.net/replicant/ConfigurationModules/
     *
     * @experimental
     */
    modules?: T['modules'];

    /**
     * Configure a set of properties derived from the user state that are indexed and searchable with the `api.searchPlayers` and `api.countPlayers` APIs.
     *
     * Use the `createComputedProperties` helper to create the configuration object.
     *
     * @see https://docs.dev.gc-internal.net/replicant/Indexing/
     */
    computedProperties?: T['computedProperties'];

    /**
     * Configure a set of scripts to use for sorting search results in the `api.searchPlayers` API.
     *
     * Use the `createComputedPropertyScripts` helper to create the configuration object.
     *
     * **Only enabled in Replicant online mode**.
     *
     * @see https://docs.dev.gc-internal.net/replicant/Indexing/#script-based-sorting
     */
    computedPropertyScripts?: ComputedPropertyScripts<any>;

    scheduledActions?: T['scheduledActions'];
    asyncGetters?: T['asyncGetters'];

    /**
     * Platform chatbot configuration.
     *
     * Use the `createChatbotConfig` helper to create the configuration object.
     *
     * @see https://docs.dev.gc-internal.net/replicant/index.html#chatbot-integration
     */
    chatbot?: ChatbotConfig<
        T['state'],
        T['messages'],
        T['scheduledActions'],
        T['computedProperties'],
        T['chatbotAssets'],
        T['ruleset'],
        T['sharedStates'],
        T['asyncGetters'],
        T['modules']
    >;

    /**
     * An encoder for chatbot message payloads.
     *
     * It's expected to receive an anlytics payload and output an encoded payload.
     * The encoded payload is sent with the chatbot message and may be retrieved on entry.
     * The encoded payload is *NOT* sent to analytics.
     */
    encodePlatformMessagePayload?: (
        payload: AnalyticsPayload,
        api: ReplicantPlatformMessagePayloadEncoderAPI,
    ) => Promise<AnalyticsPayload>;

    /**
     * Defines the Amplitude user properties set alongside each [analytics event tracked by Replicant](https://docs.dev.gc-internal.net/gcinstant/events-reference/index.html#replicant-events).
     *
     * Any duplicate user properties defined in [`api.sendAnalyticsEvents`](https://docs.dev.gc-internal.net/replicant/API/index.html#sendanalyticsevents)
     * or [`api.chatbot.sendMessage`](https://docs.dev.gc-internal.net/replicant/API/index.html#chatbotsendmessage) take precedence over the user properties defined here.
     *
     * @default `undefined`, no extra user properties attached to events.
     */
    getReceiverUserProperties?: (
        receiverState: WithMeta<T['state'], T['ruleset'], {}>,
        api: {
            date: {
                /** @returns Server time in milliseconds since the UNIX epoch. */
                now: () => number;
            };
        },
    ) => { [propertyName: string]: unknown };

    migrator: ReplicantMigrator;
    appName: string;
    version: string;

    adminTool?: {
        profile?: AdminToolProfileConfig<T['computedProperties']>;
        adminMessageGroups?: { [name: string]: (keyof FilterAdminMessages<T['messages']>)[] };

        /** Define chatbot templates here to make them testable from the CS tool. */
        chatbotMessageTemplates?: RenderTemplatesWithName<any>;
    };

    /**
     * Assign A/B tests before resolving the `onWebhook` chatbot event handler.
     *
     * Only enable this option in case of webhook-based games with no client logins:
     * otherwise clients may encounter `out of sync` errors when rolling out A/B test changes.
     *
     * Only enabled on the LINE, Instagram, Messenger and Telegram `onWebhook` handlers.
     *
     * @default `false`
     */
    assignAbTestsOnWebhook?: boolean;

    /**
     * A handler called on the client in case of a replication error.
     *
     * @see https://docs.dev.gc-internal.net/replicant/index.html#error-handling
     */
    onError?: OnErrorHandler;

    /** Name of an action invoked on the backend when logging in. */
    onLoginAction?: T['onLoginAction'];

    /**
     * One-time password configuration.
     *
     * @see https://docs.dev.gc-internal.net/replicant/OneTimePassword/
     */
    otp?: {
        /** Templates for customizing OTP code SMS messages. */
        smsTemplates?: {
            [templateId in T['otpTemplateId']]: (otpCode: string, templateData?: Record<string, string>) => string;
        };
    };

    /** @see https://docs.dev.gc-internal.net/replicant/ab-tests/ */
    ruleset?: T['ruleset'];

    /**
     * Shared states configuration.
     *
     * @see https://docs.dev.gc-internal.net/replicant/shared-states/
     */
    sharedStates?: T['sharedStates'];

    /**
     * Authenticate users with LINE Login API access tokens instead of the default LINE Game SDK tokens.
     *
     * @default `false`
     */
    enableLineAccessTokenAuthentication?: boolean;

    /**
     * Fall back to LINE Game user token verification if LINE access token verification fails on login.
     *
     * Does nothing if `enableLineAccessTokenAuthentication` is disabled.
     *
     * @default `false`
     */
    enableLineUserTokenFallback?: boolean;

    /**
     * Set to `false` to disable `api.postMessage` missing receiver errors.
     *
     * @default `true`
     */
    throwOnMessageReceiverNotFound?: boolean;

    /**
     * Set to `true` to index anonymous web players.
     *
     * @default `false`
     */
    indexAnonymousWebPlayers?: boolean;

    /**
     * Set to `true` to splice action analytics events for the current user to a
     * callback registered via `ClientReplicant::extras.setActionAnalyticsCallback`.
     */
    sendActionAnalyticsWithClient?: boolean;
}

/**
 * Utility type for extracting Replicant type out of the configuration object
 * returned from `createConfig`.
 *
 * @example ```typescript
 * const config = createConfig({ ... });
 * type MyReplicant = ReplicantFromConfig<typeof config>;
 * ```
 */
export type ReplicantFromConfig<TConfig> = TConfig extends ReplicantConfig<infer TReplicant> ? TReplicant : never;

// For type inference.
export function createConfig<
    TUserState,
    TActions extends Actions<
        ReplicantWithModules<
            PartialReplicant<{
                asyncGetters: TAsyncGetters;
                chatbotAssets: TChatbotAssets;
                computedProperties: TComputedProperties;
                messages: TMessages;
                ruleset: TRuleset;
                scheduledActions: TScheduledActions;
                sharedStates: TSharedStates;
                state: TUserState;
            }>,
            TModules
        >
    >,
    TMessages extends Messages<TUserState, TRuleset, TSharedStates, TModules> = {},
    TComputedProperties extends ComputedProperties = any,
    TScheduledActions extends ScheduledActions = any,
    TAsyncGetters extends AsyncGetters<TUserState, TRuleset, TComputedProperties, TSharedStates, TModules> = any,
    TChatbotAssets extends ChatbotAssets = any,
    TOnLoginAction extends keyof TActions = never,
    TRuleset extends Ruleset = EmptyRuleset,
    TOtpTemplateId extends string = string,
    TSharedStates extends SharedStates = {},
    TModules extends ReplicantModule[] = [],
>(
    opts: ReplicantConfig<{
        state: TUserState;
        actions: TActions;
        messages: TMessages;
        computedProperties: TComputedProperties;
        scheduledActions: TScheduledActions;
        asyncGetters: TAsyncGetters;
        chatbotAssets: TChatbotAssets;
        onLoginAction: TOnLoginAction;
        ruleset: TRuleset;
        otpTemplateId: TOtpTemplateId;
        sharedStates: TSharedStates;
        modules: TModules;
    }>,
): ReplicantConfig<
    ReplicantWithModules<
        {
            state: TUserState;
            actions: TActions;
            messages: TMessages;
            computedProperties: TComputedProperties;
            scheduledActions: TScheduledActions;
            asyncGetters: TAsyncGetters;
            chatbotAssets: TChatbotAssets;
            onLoginAction: TOnLoginAction;
            ruleset: TRuleset;
            otpTemplateId: TOtpTemplateId;
            sharedStates: TSharedStates;
            modules: TModules;
        },
        TModules
    >
> {
    validateConfig(opts);

    opts.ruleset = extendRulesetWithDefaults(opts.ruleset);

    return mergeModuleConfig(opts, opts.modules);
}

/** @returns `true` if `config` includes searchable user computed properties or shared state computed properties. */
export function hasSearchableProperties(config: {
    computedProperties?: ComputedProperties;
    sharedStates?: SharedStates;
}): boolean {
    return (
        Object.values(config?.computedProperties ?? {}).some((computedProperty) => computedProperty._searchable) ||
        Object.values(config?.sharedStates ?? {}).some((sharedState) => sharedStateHasSearchableProperties(sharedState))
    );
}

export function validateConfig(config: any) {
    function fail(err: string): never {
        throw new Error(err);
    }

    !!config || fail('Replicant config not present. Make sure to make default export of the config module.');
    typeof config === 'object' || fail('Replicant config not an object.');
    'stateSchema' in config || fail('No stateSchema in Replicant config');
    typeof config.stateSchema === 'object' || fail('stateSchema is not an object.');
    !!config.stateSchema.__schema || fail('stateSchema not a SchemaBuilder object');
    'actions' in config || fail(`No actions in Replicant config. Keys: ${Object.keys(config) as any}`);
    'migrator' in config || fail('No migrator in Replicant config');
    'version' in config || fail('Version missing in Replicant config');
    'appName' in config || fail('appName missing in Replicant config');
    typeof config.appName === 'string' || fail('appName not a string in Replicant config');
    typeof config.version === 'string' || fail('Version not a string in Replicant config: ' + config.version);

    // Validate against user-defined stateSchema overwriting system-defined state properties:
    for (const key in config.stateSchema.getDefault()) {
        if (systemStateFieldNames.includes(key as any)) {
            fail(`stateSchema.${key} is a reserved property - try using another name`);
        }
    }

    function validateAction(action: any, actionName: string): void {
        !actionName.startsWith('_') || fail('Action name cannot start with _');
        typeof action.type === 'string' || fail(`Type flag in actions.${actionName} not a string.`);
        typeof action.fn === 'function' || fail(`Function in actions.${actionName} not a function.`);
    }

    for (const fnName in config.actions) {
        if (config.actions.hasOwnProperty(fnName)) {
            const actionOrNamespace = config.actions[fnName];

            const isNamespace = !('type' in actionOrNamespace && 'fn' in actionOrNamespace);

            if (isNamespace) {
                for (const nestedFnName in actionOrNamespace) {
                    validateAction(actionOrNamespace[nestedFnName], nestedFnName);
                }
            } else {
                validateAction(actionOrNamespace, fnName);
            }
        }
    }

    // Chatbot config validation.
    if (typeof config.chatbot !== 'undefined') {
        if (typeof config.chatbot.events !== 'undefined') {
            if ('onGameEnd' in config.chatbot.events) {
                typeof config.chatbot.events.onGameEnd === 'function' ||
                    fail('chatbot.events.onGameEnd not a function: ' + typeof config.chatbot.events.onGameEnd);
            }

            if ('onWebhook' in config.chatbot.events) {
                typeof config.chatbot.events.onWebhook === 'function' ||
                    fail('chatbot.events.onWebhook not a function: ' + typeof config.chatbot.events.onWebhook);
            }
        }

        if ('assets' in config.chatbot) {
            typeof config.chatbot.assets === 'object' ||
                fail(
                    'chatbot.assets is not an object, use the renderTemplatesWithAssets helper to create this configuration.',
                );

            const localAssetPaths = Object.values(config.chatbot.assets as ChatbotAssets).flatMap((localPath) =>
                typeof localPath === 'string' ? [localPath] : Object.values(localPath),
            );
            for (const filePath of localAssetPaths) {
                if (filePath.includes('\\')) fail('chatbot.assets contains invalid path separator: ' + filePath);
            }
        }
    }

    // AsyncGetters validation.
    if (typeof config.asyncGetters !== 'undefined') {
        (typeof config.asyncGetters === 'object' && !Array.isArray(config.asyncGetters)) ||
            fail('asyncGetters is not an object');
        for (const key in config.asyncGetters) {
            const asyncGetter = config.asyncGetters[key];

            if (typeof asyncGetter === 'function') {
                continue;
            } else if (typeof asyncGetter === 'object' && asyncGetter) {
                for (const nestedKey in asyncGetter) {
                    if (typeof asyncGetter[nestedKey] !== 'function') {
                        fail(`asyncGetters.${key}.${nestedKey} is not a function`);
                    }
                }
            } else {
                fail(`asyncGetters.${key} is not a function or a map of functions`);
            }
        }
    }

    // onError validation.
    if (typeof config.onError !== 'undefined') {
        typeof config.onError === 'function' || fail('onError is not a function.');
    }

    // onLoginAction validation.
    if (config.onLoginAction && !(config.onLoginAction in config.actions)) {
        fail('onLoginAction is not a valid action');
    }

    if (typeof config.computedProperties !== 'undefined') {
        const computed = config.computedProperties as ComputedProperties;
        for (const [key, val] of Object.entries(computed)) {
            if (key.includes('.') || key.includes('>')) {
                fail(`computed property name '${key}' contains illegal characters`);
            }

            // Validate against nested computed property values:
            if (val.type.isObjectSchema()) {
                if (Object.keys(val.type.getFullSchema()).length === 0) {
                    fail('object schema must have at least one property');
                }

                for (const innerKey in val.type.getFullSchema()) {
                    const innerSchema = val.type.getFullSchema()[innerKey] as SB.Schema<any>;
                    if (innerSchema.isArraySchema()) {
                        const itemSchema = innerSchema.getItemSchema();
                        if (itemSchema.isArraySchema() || itemSchema.isObjectSchema()) {
                            fail(
                                `invalid nested object in computedProperties: ${key}.${innerKey} array must only include primitive items`,
                            );
                        }
                    }
                    if (innerSchema.isObjectSchema()) {
                        fail(`invalid nested object in computedProperties: ${key}.${innerKey} cannot be an object`);
                    }
                }
            }

            if (val.type.isArraySchema()) {
                const itemSchema = val.type.getItemSchema();
                if (itemSchema.isObjectSchema()) {
                    for (const innerKey in itemSchema.getFullSchema()) {
                        const innerSchema = itemSchema.getFullSchema()[innerKey] as SB.Schema<any>;
                        if (innerSchema.isArraySchema()) {
                            const itemSchema = innerSchema.getItemSchema();
                            if (itemSchema.isArraySchema() || itemSchema.isObjectSchema()) {
                                fail(
                                    `invalid nested object in computedProperties: ${key}[].${innerKey} array must only include primitive items`,
                                );
                            }
                        }
                        if (innerSchema.isObjectSchema()) {
                            fail(
                                `invalid nested object in computedProperties: ${key}[].${innerKey} cannot be an object`,
                            );
                        }
                    }
                }
            }
        }
    }

    // adminTool validation
    const adminProperties: any[] = config.adminTool?.profile || [];
    adminProperties.forEach((property, i) => {
        typeof property === 'object' || fail(`adminTool.profile[${i}] is not an object`);
        typeof property.property === 'string' || fail(`adminTool.profile[${i}].property is not a string`);

        const computedProperty = config.computedProperties?.[property.property];
        computedProperty || fail(`adminTool.profile[${i}].property is not found among computedProperties`);

        if (property.role) {
            property.role in adminProfilePropRoles || fail(`adminTool.profile[${i}].role is not supported`);

            const roleSchema = adminProfilePropRoles[property.role as keyof typeof adminProfilePropRoles];
            const roleSchemaString = JSON.stringify(roleSchema.serialize());
            const computedPropertySchemaString = JSON.stringify(computedProperty.type.serialize());
            roleSchemaString === computedPropertySchemaString ||
                fail(
                    `adminTool.profile[${i}].property type does not match the role requirement.` +
                        `The role expects ${roleSchemaString}, but the computed property type is ${computedPropertySchemaString}`,
                );
        }
    });

    const adminMessageNames: string[] = [];

    if (config.messages) {
        for (const messageName in config.messages) {
            if (config.messages.hasOwnProperty(messageName) && config.messages[messageName].isAdmin) {
                adminMessageNames.push(messageName);
            }
        }
    }

    const adminMessageGroups: { [name: string]: [] } = config.adminTool?.adminMessageGroups || {};

    Object.keys(adminMessageGroups).length &&
        !config.messages &&
        fail(
            `adminTool.adminMessageGroups contains grouped messages, while there's no messages provided in config.messages`,
        );

    for (const groupTitle in adminMessageGroups) {
        if (!adminMessageGroups.hasOwnProperty(groupTitle)) continue;

        const groupMessages: [] = adminMessageGroups[groupTitle]!;

        Array.isArray(groupMessages) || fail(`adminTool.adminMessageGroups[${groupTitle}].messages is not an array`);

        for (const messageName of groupMessages) {
            adminMessageNames.includes(messageName) ||
                fail(
                    `adminTool.adminMessageGroups[${groupTitle}].messages contains message ${
                        messageName as any
                    }, which is not an admin message`,
                );
        }
    }

    // OTP config validation
    if (config.otp) {
        if (config.otp.smsTemplates) {
            if (typeof config.otp.smsTemplates !== 'object') {
                fail('otp.smsTemplates must be an object');
            }

            for (const templateId in config.otp.smsTemplates) {
                if (typeof config.otp.smsTemplates[templateId] !== 'function') {
                    fail(
                        `otp.smsTemplates.${templateId} must be a function of type (otpCode: string, vars?: Record<string, string>) => string`,
                    );
                }

                const testCode = '123456';
                const testTemplateOutput = config.otp.smsTemplates[templateId](testCode);
                if (typeof testTemplateOutput !== 'string' || !testTemplateOutput.includes(testCode)) {
                    fail(`otp.smsTemplates.${templateId} must return a string that includes the OTP code argument`);
                }
            }
        }
    }

    // Ruleset validation
    if (config.ruleset) {
        rulesetSchema.tryValidate(config.ruleset);

        // Validate dependsOn parent tests
        const ruleset: ExtractType<typeof rulesetSchema> = config.ruleset;
        if (ruleset.abTests) {
            for (const [testId, testConfig] of Object.entries(ruleset.abTests)) {
                if (testConfig.dependsOn) {
                    for (const [parentTestId, validBuckets] of Object.entries(testConfig.dependsOn)) {
                        const parentTest = ruleset.abTests[parentTestId];
                        if (!parentTest) {
                            fail(
                                `ruleset.abTests.${testId}.dependsOn.${parentTestId} refers to a parent test ID that does not exist.`,
                            );
                        }
                        for (const parentBucket of validBuckets) {
                            if (!parentTest.buckets.some((bucket) => bucket.id === parentBucket)) {
                                fail(
                                    `ruleset.abTests.${testId}.dependsOn.${parentTestId} refers to a bucket ID that does not exist in the parent test.`,
                                );
                            }
                        }
                    }
                }
            }
        }
    }

    // Check that the default scheme actually validates. The getDefault() method will throw if the default value does
    // not validate.
    config.stateSchema.getDefault();
}
