// window method implementations compatible with both NodeJS and browser environments.

/** @returns `true` if the current execution environment is the browser and `false` if NodeJS. */
export function isBrowser(): boolean {
    return typeof window !== 'undefined';
}

export const atob = (base64str: string): string =>
    isBrowser() ? window.atob(base64str) : Buffer.from(base64str, `base64`).toString(`binary`);

export const btoa = (utf8str: string): string =>
    isBrowser() ? window.btoa(utf8str) : Buffer.from(utf8str, 'binary').toString(`base64`);

export const setTimeout = (handler: () => void, timeout: number): number | NodeJS.Timeout =>
    isBrowser() ? window.setTimeout(handler, timeout) : global.setTimeout(handler, timeout).unref();
