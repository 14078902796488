import * as d from '@play-co/js-codecs/decoders';

/** Adds a minimum-value constraint to numbers. */
const minCombinator = (min: number, decoder: d.Decoder<number>) =>
    decoder.flatMap((value) =>
        value < min ? d.fail(`Unexpected value: expected >= ${min}, found ${value}`) : d.pure(value),
    );

/** Adds a maximum-value constraint to numbers. */
const maxCombinator = (max: number, decoder: d.Decoder<number>) =>
    decoder.flatMap((value) =>
        value > max ? d.fail(`Unexpected value: expected <= ${max}, found ${value}`) : d.pure(value),
    );

/** Adds a minimum-length constraint to values with a `.length` property. */
const minLengthCombinator = <A extends { length: number }>(minLength: number, decoder: d.Decoder<A>) =>
    decoder.flatMap((value) =>
        value.length < minLength
            ? d.fail(`Unexpected value: expected .length >= ${minLength}, found ${value.length}`)
            : d.pure(value),
    );

/** Adds a maximum-length constraint to values with a `.length` property. */
const maxLengthCombinator = <A extends { length: number }>(maxLength: number, decoder: d.Decoder<A>) =>
    decoder.flatMap((value) =>
        value.length > maxLength
            ? d.fail(`Unexpected value: expected .length <= ${maxLength}, found ${value.length}`)
            : d.pure(value),
    );

export {
    minCombinator as min,
    maxCombinator as max,
    minLengthCombinator as minLength,
    maxLengthCombinator as maxLength,
};
