import { APIMetainfo, PurchaseInfo } from '../../ReplicantAPI';

export function getPurchaseHistory(metainfo: APIMetainfo): PurchaseInfo[] {
    if (!metainfo.purchaseHistory) {
        return [];
    }

    return metainfo.purchaseHistory.map((info) => {
        const result = { ...info };

        // Convert second-level timestamps to milliseconds:
        const isMillisecondTimestamp = info.purchase_time.toString().length === 13;
        if (isMillisecondTimestamp) {
            result.issued_at = Number(info.issued_at);
            result.purchase_time = Number(info.purchase_time);
        } else {
            result.issued_at = Number(info.issued_at) * 1000;
            result.purchase_time = Number(info.purchase_time) * 1000;
        }

        return result;
    });
}
