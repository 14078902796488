import type { KeyValuePairs, KeyValueStoreAPI, KeyValueStoreSendOpts } from '../api/KeyValueStoreAPI';
import { ReplicantError } from '../Errors';
import { KvPairOutput } from '../server/ServerReplicant';
import ReplicantHttpClient from './ReplicantHttpClient';

export default class ClientKeyValueStore implements KeyValueStoreAPI {
    constructor(
        private httpClient: ReplicantHttpClient,
        private cache: KeyValuePairs = {},
    ) {}

    async get(key: string, opts?: { forceFetch?: boolean }) {
        const pairs = await this.getBatch([key], opts);

        return pairs[key] || null;
    }

    async getBatch(keys: string[], opts?: { forceFetch?: boolean }) {
        const keysToFetch = opts && opts.forceFetch ? keys : keys.filter((key) => !(key in this.cache));

        if (keysToFetch.length) {
            await this.fetchBatch(keysToFetch);
        }

        const result: KeyValuePairs = {};
        for (const key of keys) {
            if (key in this.cache) {
                result[key] = this.cache[key]!;
            }
        }

        return result;
    }

    async send(key: string, value: string, opts?: KeyValueStoreSendOpts) {
        return this.sendBatch({ [key]: value }, opts);
    }

    async sendBatch(pairs: KeyValuePairs, opts?: KeyValueStoreSendOpts): Promise<void> {
        for (const key in pairs) {
            if (!pairs[key] || typeof pairs[key] !== 'string') {
                throw new ReplicantError(
                    `Invalid value for key: '${key}'.`,
                    'replication_error',
                    'key_value_storage_error',
                );
            }
        }

        const payload = { pairs, opts };
        await this.httpClient.doSendKeyValuePairsRequest(payload, {
            defaultErrorCodes: {
                code: 'replication_error',
                subCode: 'key_value_storage_error',
            },
            retryOptions: { retries: 8 },
        });

        for (const key in pairs) {
            this.cache[key] = pairs[key]!;
        }
    }

    private async fetchBatch(keys: string[]): Promise<void> {
        const json: KvPairOutput = await this.httpClient.doGetKeyValuePairsRequest(
            { keys },
            {
                defaultErrorCodes: {
                    code: 'replication_error',
                    subCode: 'key_value_storage_error',
                },
                retryOptions: { retries: 8 },
            },
        );

        if (!json.data) {
            throw new ReplicantError(
                'Invalid key value store fetch response.',
                'replication_error',
                'key_value_storage_error',
            );
        }

        for (const key in json.data) {
            this.cache[key] = json.data[key]!;
        }
    }
}
