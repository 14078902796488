import { AsyncActionApiResult } from '../../client/ClientReplicantQueue';
import { ReplicantABTests } from '../../common/ReplicantABTests';
import { ChatbotMetainfo, SharedStateUserItem, UserSharedStateItems } from '../../db/DB';
import { ReplicantError } from '../../Errors';
import { Replicant } from '../../ReplicantConfig';
import { deserializeError } from '../../utils/ErrorUtils';
import { APIMetainfo, ReplicantAsyncActionAPI } from '../ReplicantAPI';
import { createReplicantAPIClient } from './ReplicantClientImpl';
import { ActionAnalyticsCallback } from '../../common/Analytics';

export class AsyncAPICache<T extends Replicant> {
    private api: ReplicantAsyncActionAPI<T>;
    private apiState: { resultIndex: number; apiCallQueue: string[] };
    private receivedProfiles: {} = {};
    private results: AsyncActionApiResult[] = [];
    private analyticsCallback: ActionAnalyticsCallback = () => {};

    constructor(params: {
        id: () => string;
        sessionId: () => string;
        apiMetaInfo: () => APIMetainfo;
        userSharedStates: () => UserSharedStateItems<T['sharedStates']>;
        chatbotMetainfo: () => ChatbotMetainfo;
        invokeTime: () => number;
        messages: () => T['messages'];
        scheduledActions: () => T['scheduledActions'];
        computedProperties: () => T['computedProperties'];
        asyncGetters: () => T['asyncGetters'];
        sharedStates: () => T['sharedStates'] | undefined;
        ruleset: () => T['ruleset'] | undefined;
        onMessagePosted: (receiverId: string) => void;
        userAssetsBaseUrl: () => string;
        abTestsApiAccess: () => ReplicantABTests;
    }) {
        const wrap = (obj: any, prefix: string) => {
            for (const key in obj) {
                if (typeof obj[key] === 'function') {
                    const origFunction = obj[key];
                    obj[key] = ((...args: unknown[]) => {
                        const res = this.results[this.apiState.resultIndex];
                        this.apiState.resultIndex++;
                        this.apiState.apiCallQueue.push(prefix + key);

                        if (!res) {
                            throw new ReplicantError(
                                'Client action trying to execute more api calls than server. Client queue: ' +
                                    this.apiState.apiCallQueue.join(', ') +
                                    '. Server results array: ' +
                                    JSON.stringify(this.results),
                                'replication_error',
                                'async_action_api_error',
                            );
                        }

                        if (!res.failed) {
                            if (prefix + key === 'api.setClockOffset') {
                                const newClockOffset = args[0];

                                if (typeof newClockOffset === 'number') {
                                    params.apiMetaInfo().clockOffset = Math.floor(newClockOffset);
                                }
                            }

                            if (prefix + key === 'api.fetchStates') {
                                this.receivedProfiles = {
                                    ...this.receivedProfiles,
                                    ...res.value,
                                };
                            }

                            if (prefix === 'api.postMessage.') {
                                params.onMessagePosted(args[0] as string);
                            }

                            // Assignment modifies metainfo, so we need to perform it manually.
                            if (prefix === 'api.abTests.' && (key === 'assign' || key === 'unassign')) {
                                params
                                    .abTestsApiAccess()
                                    [key](params.apiMetaInfo(), params.id(), ...(args as [string, string?]));
                            }

                            if (prefix.startsWith('api.sharedStates') && key === 'setUserState') {
                                const userSharedStates = params.userSharedStates();

                                type StateName = keyof typeof userSharedStates;
                                type StateID = keyof (typeof userSharedStates)[StateName];
                                type Item = SharedStateUserItem<T['sharedStates'], StateName>;
                                type State = Item['state'];

                                const [, , stateName] = prefix.split('.') as ['api', 'sharedStates', StateName, ''];
                                const [stateId, payload] = args as [string, State];

                                const item: Item = {
                                    rev: 0,
                                    version: 0,
                                    state: payload,
                                    stateId,
                                    stateName,
                                    userId: params.id(),
                                };

                                userSharedStates[stateName] = {
                                    ...userSharedStates[stateName],
                                    [stateId as StateID]: item,
                                };
                            }

                            if (prefix + key === 'api.sendAnalyticsEvents') {
                                // Call the original function so user analytics
                                // get passed to the registered callback
                                origFunction.apply(obj, args);
                            }

                            return res.async ? Promise.resolve(res.value) : res.value;
                        } else {
                            if (res.async) {
                                return Promise.reject(deserializeError(res.value));
                            }

                            throw deserializeError(res.value);
                        }
                    }) as any;
                } else if (typeof obj[key] === 'object') {
                    wrap(obj[key], prefix + key + '.');
                } else if (key !== 'isAsync') {
                    throw Error('Unsupported field in API: ' + key);
                }
            }
        };

        this.apiState = { resultIndex: 0, apiCallQueue: [] as string[] };

        const outApi = createReplicantAPIClient({
            id: params.id,
            sessionId: params.sessionId,
            apiMetainfo: params.apiMetaInfo,
            userSharedStates: params.userSharedStates,
            chatbotMetainfo: params.chatbotMetainfo(),
            invokeTime: params.invokeTime,
            messages: params.messages(),
            scheduledActions: params.scheduledActions(),
            computedProperties: params.computedProperties(),
            asyncGetters: params.asyncGetters(),
            sharedStates: params.sharedStates(),
            onMessagePosted: () => null,
            userAssetsBaseUrl: params.userAssetsBaseUrl,
            abTestsApiAccess: params.abTestsApiAccess,
            getAnalyticsCallback: () => this.analyticsCallback,
        });
        wrap(outApi, 'api.');
        this.api = outApi;
    }

    getAPI(results: AsyncActionApiResult[]): ReplicantAsyncActionAPI<T> {
        this.receivedProfiles = {};
        this.apiState.apiCallQueue = [];
        this.apiState.resultIndex = 0;
        this.results = results;
        return this.api;
    }

    getReceivedProfiles() {
        return this.receivedProfiles;
    }

    getAPIState() {
        return this.apiState;
    }

    setAnalyticsCallback(callback: ActionAnalyticsCallback) {
        this.analyticsCallback = callback;
    }
}
