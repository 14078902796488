export function createPromiseWithErrbackFunction<T = any>() {
    let resolve: (result: T) => void;
    let reject: (err: any) => void;

    const callback = (err: string | null | undefined, result: T | null | undefined) => {
        if (err) {
            reject(err);
        } else {
            resolve(result as T);
        }
    };
    const promise = new Promise<T>((res, rej) => {
        reject = rej;
        resolve = res;
    });

    return { promise, callback };
}

export async function delay(ms: number) {
    if (ms === 0) return;
    return new Promise((res) => setTimeout(res, ms));
}

export type RetryOptions = {
    retries: number;
    linearBackoff?: number;
    exponentialBackoff?: number;
    retryOnlyIf?: (error: any) => boolean;
};

export async function retry<T>(action: () => Promise<T>, options: RetryOptions): Promise<T> {
    if (options.linearBackoff && options.exponentialBackoff)
        throw new Error('Cannot set linearBackoff and exponentialBackoff at the same time');
    const retries = options.retries;

    const backoff = options?.linearBackoff || 0;
    let currentRetry = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
        try {
            return await action();
        } catch (e) {
            // if retryOnlyIf is defined and we don't qualify for retries, throw immediately.
            if (options?.retryOnlyIf && !options.retryOnlyIf(e)) {
                throw e;
            }

            if (currentRetry >= retries) {
                throw e;
            } else {
                currentRetry++;

                if (options?.exponentialBackoff) {
                    await delay(
                        Math.pow(2, currentRetry) * options.exponentialBackoff +
                            (Math.random() * options.exponentialBackoff - options.exponentialBackoff),
                    );
                } else {
                    await delay(currentRetry * backoff);
                }
            }
        }
    }
}

export async function retryWithTiming<T>(
    action: () => Promise<T>,
    options: RetryOptions,
): Promise<{ requestMs: number; requestTries: number; result: T }> {
    const startedAt = Date.now();
    let requestTries = 0;

    const result = await retry(async () => {
        requestTries++;
        return action();
    }, options);

    const requestMs = Date.now() - startedAt;

    return { requestMs, requestTries, result };
}

export function promiseTimeout<T>(promise: Promise<T>, timeout: number): Promise<T> {
    let handle: NodeJS.Timeout;

    const timeoutPromise = new Promise<T>((_, reject) => {
        handle = setTimeout(() => reject(new Error('Action timed out')), timeout);
    });

    return Promise.race([promise, timeoutPromise]).then(
        (result) => {
            clearTimeout(handle);
            return result;
        },
        (error) => {
            clearTimeout(handle);
            return Promise.reject(error);
        },
    );
}
