import { ABTestAssignments, APIMetainfo } from '../api/ReplicantAPI';
import { ABTestChangeEvent } from '../common/LoginInfo';
import { ReplicantError } from '../Errors';
import { teaHash } from '../math/prng/prng';
import { pickABTestBucket, type ABTestsConfig } from '../ReplicantRuleset';
import { Immutable } from '../utils/TypeUtils';
import { unreachable } from '../utils/Utils';

function failAssignment(testId: string, bucketId: string | undefined, suffix: string): never {
    const bucket = bucketId ? ` to bucket '${bucketId}'` : ``;

    throw new ReplicantError(
        `Cannot assign test '${testId}' to '${bucket}': ${suffix}`,
        'replication_error',
        'ab_tests_error',
    );
}

function failUnassignment(testId: string, suffix: string): never {
    throw new ReplicantError(`Cannot unassign test '${testId}': ${suffix}`, 'replication_error', 'ab_tests_error');
}

export class ReplicantABTests {
    constructor(
        private opts: {
            abTests: ABTestsConfig;
            dynamicConfig: Partial<{
                [testId: string]: { active: boolean; rollOut: number; stopAssignment?: boolean };
            }>;
        },
    ) {}

    getBucketID(metainfo: Immutable<APIMetainfo>, testId: string): string | undefined {
        return metainfo.abTestAssignments?.[testId]?.bucketId;
    }

    assign(metainfo: APIMetainfo, userId: string, testId: string, bucketId?: string): void {
        const ruleset = this.opts.abTests;
        if (!ruleset) {
            failAssignment(testId, bucketId, `No ab tests have been configured.`);
        }

        if (!ruleset[testId]) {
            failAssignment(testId, bucketId, `Not configured.`);
        }

        const testDynamicConfig = this.opts.dynamicConfig[testId];

        if (!testDynamicConfig?.active) {
            return; // Ignore manual assignment if test is inactive
        }

        if (testDynamicConfig?.stopAssignment) {
            return; // Ignore manual assignment if test is stopped
        }

        if (!isTestRolledOutToUser({ rollOut: testDynamicConfig.rollOut, testId, userId })) {
            return; // Ignore manual assignment if test is not rolled out to the current user
        }

        if (!bucketId) {
            bucketId = pickABTestBucket(userId, testId, ruleset);
        }

        if (!ruleset[testId]!.buckets.find((x) => x.id === bucketId)) {
            failAssignment(testId, bucketId, 'Invalid bucket.');
        }

        if (!metainfo.abTestAssignments) {
            metainfo.abTestAssignments = {};
        }

        const oldAssignment = metainfo.abTestAssignments[testId];
        const newAssignmentType = (() => {
            const oldAssignmentType = oldAssignment?.type;

            switch (oldAssignmentType) {
                // automatic -> manual means the test was active
                case 'automatic':
                    return 'manual-active';

                // previous manual assignment. keep it.
                case 'manual-active':
                case 'manual-inactive':
                    return oldAssignmentType;

                // missing -> manual means the test was inactive
                case undefined:
                    return 'manual-inactive';

                case 'stopped':
                    throw Error('A/B test `stopped` type should not be stored in metainfo');

                default:
                    unreachable(oldAssignmentType);
            }
        })();

        metainfo.abTestAssignments[testId] = { ...oldAssignment, bucketId, type: newAssignmentType };
    }

    unassign(metainfo: APIMetainfo, userId: string, testId: string) {
        const assignments = metainfo.abTestAssignments;
        if (!assignments) {
            failUnassignment(testId, 'No tests assigned.');
        }

        const assignment = assignments[testId];
        if (!assignment) {
            failUnassignment(testId, 'Test not assigned.');
        }

        const testIsStopped = this.opts.dynamicConfig[testId]?.stopAssignment;
        if (testIsStopped) {
            delete metainfo.abTestAssignments?.[testId]; // Manually unassigning a stopped test removes the assignment
        }

        switch (assignment.type) {
            case 'automatic': {
                // Test already unassigned.
                // There is no way for the user to know this, so this is not an error.
                // Do nothing.
                break;
            }

            case 'manual-active': {
                const ruleset = this.opts.abTests;
                if (!ruleset) {
                    failUnassignment(testId, `No ab tests have been configured.`);
                }

                const bucketId = pickABTestBucket(userId, testId, ruleset);

                if (!ruleset[testId]!.buckets.find((x) => x.id === bucketId)) {
                    failAssignment(testId, bucketId, 'Invalid bucket.');
                }

                assignment.bucketId = bucketId;

                break;
            }

            case 'manual-inactive': {
                delete metainfo.abTestAssignments?.[testId];
                break;
            }

            case 'stopped':
                throw Error('A/B test `stopped` type should not be stored in metainfo');

            default:
                throw unreachable(assignment.type);
        }
    }
}

export function calculateAssignmentChanges(opts: {
    newAssignments: ABTestAssignments;
    oldAssignments: ABTestAssignments;
}): ABTestChangeEvent[] {
    const newTestIds = Object.keys(opts.newAssignments);
    const oldTestIds = Object.keys(opts.oldAssignments);
    const repeatingTestIds = [...newTestIds, ...oldTestIds];
    const testIds = repeatingTestIds.filter((testId, i) => repeatingTestIds.indexOf(testId) === i); // Unique.

    return testIds
        .map((testId) => {
            const newAssignment = opts.newAssignments[testId];
            const oldAssignment = opts.oldAssignments[testId];

            const bucketId = newAssignment?.bucketId;
            const previousBucketId = oldAssignment?.bucketId;

            // Match AB test config except when assigned manually, in which case it's always false.
            const newUsersOnly = !!newAssignment?.newUsersOnly && newAssignment?.type === 'automatic';

            return { testId, bucketId, previousBucketId, newUsersOnly };
        })
        .filter(({ bucketId, previousBucketId }) => bucketId !== previousBucketId);
}

export function isPlaceboTestId(abTestId: string): boolean {
    return abTestId.startsWith('0000_placebo');
}

export function isTestRolledOutToUser(opts: { rollOut: number; userId: string; testId: string }): boolean {
    const necessaryRollOut = teaHash(`${opts.userId}_${opts.testId}_roll_out`);

    return opts.rollOut > necessaryRollOut;
}
