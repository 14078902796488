import logger, { LogLevel } from '../logger';
import { Replicant, ReplicantConfig } from '../ReplicantConfig';
import { OnlinePlatform, toReplicantPlatform } from '../ReplicantPlatform';
import { ClientReplicant, ReplicantConnectionOptions } from './ClientReplicant';
import { CommonReplicantOpts, createReplicant, OnLoginActionArgs } from './createReplicant';

// Helper functions to creating online replicant clients.
export type OnlineReplicantOpts = { platform: OnlinePlatform } & CommonReplicantOpts & ReplicantConnectionOptions;

export async function createOnlineReplicant<T extends Replicant>(
    config: ReplicantConfig<T>,
    id: string,
    { kvStore, ...options }: OnlineReplicantOpts & OnLoginActionArgs<T>,
): Promise<ClientReplicant<T>> {
    if (options.debug) {
        logger.level(LogLevel.debug);
    }

    // Validate platform.
    const platform = toReplicantPlatform(options.platform);
    if (platform === 'mock') {
        throw new Error('Cannot create an online replicant with platform set to mock.');
    }

    const { replicant } = await createReplicant({
        config,
        id,
        options,
        keyValueStorePrefetchKeys: kvStore?.prefetchKeys,
        internalKeyValueStorePrefetchKeys: kvStore?.prefetchInternalKeys,
    });

    return replicant;
}
