import type { ReplicantEventHandlerAPI } from './api/ReplicantAPI';
import type { AsyncGetters } from './ReplicantAsyncGetters';
import type { ComputedProperties } from './ReplicantComputedProperties';
import type { PartialReplicant, Replicant } from './ReplicantConfig';
import { Messages } from './ReplicantMessages';
import { ReplicantModule, ReplicantWithModules } from './ReplicantModules';
import { EmptyRuleset, Ruleset } from './ReplicantRuleset';
import type { SharedStates } from './ReplicantSharedStates';
import SB from './SchemaBuilder';
import { WithMeta } from './systemStateFields';
import { mapObject } from './utils/ObjUtils';

export type ScheduledActionsSchema<TSchema extends { [key: string]: SB.Schema }> = TSchema;

export type ScheduledActions<
    T extends Replicant = any,
    TScheduledActionsSchema extends ScheduledActionsSchema<any> = any,
> = {
    [K in keyof TScheduledActionsSchema]: {
        schema: TScheduledActionsSchema[K];
        action: (
            state: WithMeta<T['state'], T['ruleset'], T['sharedStates']>,
            args: SB.ExtractType<TScheduledActionsSchema[K]>,
            api: ReplicantEventHandlerAPI<
                T & {
                    scheduledActions: {
                        [D in keyof TScheduledActionsSchema]: {
                            schema: TScheduledActionsSchema[D];
                            action: (state: any, args: any, api: any) => Promise<void> | void;
                        };
                    };
                }
            >,
        ) => Promise<void> | void;
    };
};

export function createScheduledActions<
    TScheduledActionsSchema extends { [key: string]: SB.Schema },
    TState,
    TRuleset extends Ruleset = EmptyRuleset,
    TMessages extends Messages<TState, TRuleset, TSharedStates, TModules> = {},
    TComputedProperties extends ComputedProperties = {},
    TSharedStates extends SharedStates = {},
    TAsyncGetters extends AsyncGetters<TState, TRuleset, TComputedProperties, TSharedStates, TModules> = {},
    TModules extends ReplicantModule[] = [],
>(
    stateSchema: SB.Schema<TState>,
    schema: TScheduledActionsSchema,
    _?: {
        ruleset?: TRuleset;
        messages?: TMessages;
        computedProperties?: TComputedProperties;
        sharedStates?: TSharedStates;
        asyncGetters?: TAsyncGetters;
        modules?: TModules;
    },
) {
    type TReplicant = ReplicantWithModules<
        PartialReplicant<{
            state: TState;
            messages: TMessages;
            ruleset: TRuleset;
            computedProperties: TComputedProperties;
            sharedStates: TSharedStates;
            asyncGetters: TAsyncGetters;
        }>,
        TModules
    >;

    type TScheduledActions = ScheduledActions<TReplicant, TScheduledActionsSchema>;

    return function createScheduledActionsFromSchema(actions: {
        [K in keyof TScheduledActions]: TScheduledActions[K]['action'];
    }): TScheduledActions {
        return mapObject(actions, (key, action) => ({ schema: schema[key] as any, action }));
    };
}
