import { api, APIPath, PostAPI } from '../api/API';
import type { OTPRequestResponse } from '../common/OTP';
import { PushNotificationPlatformResponses, PushNotificationResponse } from '../common/PushNotifications';
import type { OAuthResponse } from '../common/Types';
import { AuthErrorSubCode, ReplicantError, ReplicantErrorCode, ReplicantErrorSubCode } from '../Errors';
import { Replicant } from '../ReplicantConfig';
import {
    CreateOrLoginOutput,
    CreateOrLoginWebPlayerOutput,
    FetchStatesOutput,
    GetTokenOutput,
    InferGenderFromNameOutput,
    KvPairOutput,
    ReplicateOutput,
    SetTestUsersOutput,
    UserAssetUploadOutput,
    WriteKvPairsOutput,
} from '../server/ServerReplicant';
import { retry, RetryOptions } from '../utils/AsyncUtils';
import ClientSignatureManager from './ClientSignatureManager';

const headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'text/plain',
};

// Handles all http requests to the replicant server.
// Encapsulates retry logic and generic error handling.

type PostArgs<TUrl extends keyof PostAPI> = Omit<PostAPI[TUrl], 'id' | 'auth'>;

export const FORBIDDEN_RESPONSE_TROUBLESHOOTING_MSG = `Please check the following:
- Is the backend endpoint URL configured correctly? Make sure you're targeting the right deployment stage.`;

export const NETWORK_ERROR_TROUBLESHOOTING_MSG = `Please check the following:
- Is the backend endpoint URL configured correctly?
- Is the backend infrastructure provisioned?
- Is the backend deployed?`;

export const NOT_FOUND_RESPONSE_TROUBLESHOOTING_MSG = `Please check the following:
- Is the backend endpoint URL configured correctly? Make sure you're targeting the right deployment stage.
- Is the current app version deployed to the backend?`;

export default class ReplicantHttpClient<T extends Replicant = any> {
    /** `undefined` on anonymous web players login request. */
    private id?: string;
    private endpoint: string;
    private version: string;

    private fetchFn: typeof fetch;
    private retryOptions: RetryOptions;

    private signatureManager: ClientSignatureManager;

    constructor(options: {
        endpoint: string;
        fetchOverride?: typeof fetch;
        id?: string;
        isWebPlayable?: boolean;
        now: () => number;
        obtainSignature: (() => Promise<string>) | undefined;
        retryOptions: RetryOptions;
        signature?: string;
        version: string;
    }) {
        this.id = options.id;
        this.endpoint = options.endpoint;
        this.version = options.version;
        this.retryOptions = options.retryOptions;

        this.signatureManager = new ClientSignatureManager({
            initialSignature: options.signature,
            obtainSignature: options.obtainSignature,
            obtainToken: () =>
                !this.id || options.isWebPlayable ? this.doGetTokenWebPlayerRequest() : this.doGetTokenRequest(),
            now: () => options.now(),
        });

        this.fetchFn = options.fetchOverride ?? fetch.bind(window);
    }

    pause() {
        this.signatureManager.pause();
    }

    resume() {
        this.signatureManager.resume();
    }

    getToken() {
        return this.signatureManager.getToken();
    }

    async doLoginOrCreateRequest(payload: PostArgs<typeof APIPath.LOGIN_OR_CREATE>) {
        const result: CreateOrLoginOutput<T['state']> = await this.postRequest(APIPath.LOGIN_OR_CREATE, payload);

        this.signatureManager.setToken(result.data.token);

        return result;
    }

    async doLoginOrCreateWebPlayerRequest(payload: PostArgs<typeof APIPath.LOGIN_OR_CREATE_WEB_PLAYER>) {
        const result: CreateOrLoginWebPlayerOutput<T['state']> = await this.postRequest(
            APIPath.LOGIN_OR_CREATE_WEB_PLAYER,
            payload,
        );

        this.id = result.data.id;
        this.signatureManager.setToken(result.data.token);
        this.signatureManager.setSignature(result.data.webPlayerAuthToken);

        return result;
    }

    async doFetchStatesRequest(
        payload: PostArgs<typeof APIPath.FETCH_STATES>,
    ): Promise<FetchStatesOutput<T['state'], T['ruleset']>> {
        return this.postRequest(APIPath.FETCH_STATES, payload);
    }

    async doAsyncGetterRequest(payload: PostArgs<typeof APIPath.ASYNC_GETTER>): Promise<any> {
        const { result } = await this.postRequest(APIPath.ASYNC_GETTER, payload);
        return result;
    }

    async doPostAfterContextSwitchRequest(
        payload: PostArgs<typeof APIPath.AFTER_CONTEXT_SWITCH>,
        opts?: RequestOptions,
    ): Promise<void> {
        await this.postRequest(APIPath.AFTER_CONTEXT_SWITCH, payload, opts);
    }

    async doPostReplicationRequest(
        payload: PostArgs<typeof APIPath.REPLICATE>,
    ): Promise<ReplicateOutput<T['state'], T['ruleset']>> {
        return this.postRequest(APIPath.REPLICATE, payload);
    }

    async doSendKeyValuePairsRequest(
        payload: PostArgs<typeof APIPath.WRITE_KEY_VALUES>,
        opts?: RequestOptions,
    ): Promise<WriteKvPairsOutput> {
        return this.postRequest(APIPath.WRITE_KEY_VALUES, payload, opts);
    }

    async doGetKeyValuePairsRequest(
        payload: PostArgs<typeof APIPath.READ_KEY_VALUES>,
        opts?: RequestOptions,
    ): Promise<KvPairOutput> {
        return this.postRequest(APIPath.READ_KEY_VALUES, payload, opts);
    }

    doGetInternalKeyValuePairsRequest(
        payload: PostArgs<typeof APIPath.READ_INTERNAL_KEY_VALUES>,
        opts?: RequestOptions,
    ): Promise<KvPairOutput> {
        return this.postRequest(APIPath.READ_INTERNAL_KEY_VALUES, payload, opts);
    }

    async doUploadUserAssetRequest(
        payload: PostArgs<typeof APIPath.UPLOAD_USER_ASSET>,
        opts?: RequestOptions,
    ): Promise<UserAssetUploadOutput> {
        return this.postRequest(APIPath.UPLOAD_USER_ASSET, payload, opts);
    }

    async doPostAPN(
        payload: PostArgs<typeof APIPath.IOS_POST_APN>,
        opts?: RequestOptions,
    ): Promise<{ data: PushNotificationResponse }> {
        return this.postRequest(APIPath.IOS_POST_APN, payload, opts);
    }

    async doPostAndroidPushNotification(
        payload: PostArgs<typeof APIPath.ANDROID_POST_PUSH_NOTIFICATION>,
        opts?: RequestOptions,
    ): Promise<{ data: PushNotificationResponse }> {
        return this.postRequest(APIPath.ANDROID_POST_PUSH_NOTIFICATION, payload, opts);
    }

    async doPostPushNotification(
        payload: PostArgs<typeof APIPath.PUSH_NOTIFICATION>,
        opts?: RequestOptions,
    ): Promise<{ data: PushNotificationPlatformResponses }> {
        return this.postRequest(APIPath.PUSH_NOTIFICATION, payload, opts);
    }

    async doPostTestUsers(payload: PostArgs<'testUsers'>, opts?: RequestOptions): Promise<SetTestUsersOutput> {
        return this.postRequest('testUsers', payload, opts);
    }

    doPostInferGenderFromName(
        payload: PostArgs<typeof APIPath.INFER_GENDER_FROM_NAME>,
        opts?: RequestOptions,
    ): Promise<InferGenderFromNameOutput> {
        return this.postRequest(APIPath.INFER_GENDER_FROM_NAME, payload, opts);
    }

    async doPostOAuthGetAccessToken(
        payload: PostArgs<typeof APIPath.OAUTH_GET_ACCESS_TOKEN>,
        opts?: RequestOptions,
    ): Promise<OAuthResponse> {
        return this.postRequest(APIPath.OAUTH_GET_ACCESS_TOKEN, payload, opts);
    }

    async doPostOtpInitiateAddReceiver(
        payload: PostArgs<typeof APIPath.OTP_INITIATE_ADD_RECEIVER>,
        opts?: RequestOptions,
    ): Promise<OTPRequestResponse> {
        const res = await this.postRequest(APIPath.OTP_INITIATE_ADD_RECEIVER, payload, opts);
        return res.data;
    }

    async doPostOtpInitiateLogin(
        payload: PostArgs<typeof APIPath.OTP_INITIATE_LOGIN>,
        opts?: RequestOptions,
    ): Promise<OTPRequestResponse> {
        const res = await this.postRequest(APIPath.OTP_INITIATE_LOGIN, payload, opts);
        return res.data;
    }

    async doPostSocialGraphUpdateSelf(
        payload: PostArgs<typeof APIPath.SOCIAL_GRAPH_UPDATE_SELF>,
        opts?: RequestOptions,
    ): Promise<void> {
        await this.postRequest(APIPath.SOCIAL_GRAPH_UPDATE_SELF, payload, opts);
    }

    async doPostSocialGraphTrackInteractions(
        payload: PostArgs<typeof APIPath.SOCIAL_GRAPH_TRACK_INTERACTIONS>,
        opts?: RequestOptions,
    ): Promise<void> {
        await this.postRequest(APIPath.SOCIAL_GRAPH_TRACK_INTERACTIONS, payload, opts);
    }

    setSignature(newSignature: string) {
        this.signatureManager.setSignature(newSignature);
    }

    private async doGetTokenRequest() {
        const result: GetTokenOutput = await this.postRequest(APIPath.TOKEN, {});
        return result.data.token;
    }

    private async doGetTokenWebPlayerRequest() {
        const result: GetTokenOutput = await this.postRequest(APIPath.TOKEN_WEB_PLAYER, {});
        return result.data.token;
    }

    private async postRequest<TUrl extends keyof PostAPI>(
        apiEndpoint: TUrl,
        payload: PostArgs<TUrl>,
        opts?: RequestOptions,
    ) {
        const isAnonymousRequest =
            !this.id &&
            (apiEndpoint === APIPath.LOGIN_OR_CREATE_WEB_PLAYER ||
                apiEndpoint === APIPath.OAUTH_GET_ACCESS_TOKEN ||
                apiEndpoint === APIPath.OTP_INITIATE_LOGIN);

        // Omit credentials from anonymous web player login and OTP login code requests:
        const bodyObject = isAnonymousRequest
            ? payload
            : {
                  ...payload,
                  id: this.id,
                  auth:
                      // Login request is authenticated with platform signature and subsequent requests with Replicant backend-issued token:
                      apiEndpoint === APIPath.LOGIN_OR_CREATE ||
                      apiEndpoint === APIPath.LOGIN_OR_CREATE_WEB_PLAYER ||
                      apiEndpoint === APIPath.TOKEN ||
                      apiEndpoint === APIPath.TOKEN_WEB_PLAYER
                          ? await this.signatureManager.getSignature()
                          : await this.signatureManager.getToken(),
              };

        if (api[apiEndpoint].post.schema?.validate(bodyObject)) {
            throw new ReplicantError('Invalid parameters', 'replication_error');
        }

        const body = JSON.stringify(bodyObject);

        // Validate that payload size doesn't exceed ALB limit.
        if (body.length > 950000) {
            throw new ReplicantError('Client payload too large', 'replication_error', 'payload_too_large');
        }

        let response: Response;

        const endpoint = this.endpoint + '/v' + this.version + '/' + apiEndpoint;
        const retryOptions = { ...this.retryOptions, ...opts?.retryOptions };

        try {
            response = await retry(() => this.fetchFn(endpoint, { method: 'POST', headers, body }), retryOptions);
        } catch (error: any) {
            throw createNetworkError({ error, method: 'POST', path: apiEndpoint, body });
        }

        const ret = await this.parseTextAndHandleError(response, apiEndpoint, opts?.defaultErrorCodes);
        return ret;
    }

    private async parseTextAndHandleError(
        response: Response,
        apiEndpoint: string,
        errorCodes?: {
            code: ReplicantErrorCode;
            subCode?: ReplicantErrorSubCode;
        },
    ) {
        const responseText = await response.text();

        if (response.status >= 502) {
            throw new ReplicantError('Status code: ' + response.status + ', message: ' + responseText, 'server_error');
        }

        if (response.status === 403) {
            let authErrorSubCode: AuthErrorSubCode | undefined;

            try {
                const error = JSON.parse(responseText);

                if (error?.subCode in AuthErrorSubCode) {
                    authErrorSubCode = error.subCode;
                }
            } catch (e) {
                // The error text does not contain a valid JSON object.
                // Forward it as an unknown auth error.
            }

            throw new ReplicantError(
                ((responseText || '') + '\n\n' + FORBIDDEN_RESPONSE_TROUBLESHOOTING_MSG).trim(),
                'authorization_error',
                authErrorSubCode,
            );
        }

        // Check if the server has tagged the error with code and subcode, otherwise raise an unknown_error.
        if (response.status >= 400) {
            let errorMessage = responseText;
            let errorCode = errorCodes?.code ?? ReplicantErrorCode.unknown_error;
            let errorSubCode = errorCodes?.subCode;
            let clientExtras: { [key: string]: any } = {};

            // If response contains error code and subcode, assign it.
            try {
                const error = JSON.parse(responseText);

                if (error.message) {
                    errorMessage = error.message;
                }

                if (error.code in ReplicantErrorCode) {
                    errorCode = error.code;
                }

                if (error.subCode in ReplicantErrorSubCode) {
                    errorSubCode = error.subCode;
                }

                if (error.clientExtras && typeof error.clientExtras === 'object') {
                    clientExtras = error.clientExtras;
                }
            } catch (e) {
                // The error text does not contain a valid JSON object.
                // Forward it as an unknown error.
            }

            if (response.status === 404) {
                errorMessage = ((errorMessage || '') + '\n\n' + NOT_FOUND_RESPONSE_TROUBLESHOOTING_MSG).trim();
            }

            throw new ReplicantError(errorMessage, errorCode, errorSubCode, undefined, undefined, clientExtras);
        }

        try {
            const result = JSON.parse(responseText);
            return result;
        } catch (e) {
            throw new ReplicantError(
                'Payload not JSON: ' + responseText + ' for API call: ' + apiEndpoint,
                'unknown_error',
            );
        }
    }
}

function createNetworkError(opts: { error: Error; method: 'POST'; path: string; body?: unknown }): ReplicantError {
    const replicantError = new ReplicantError(
        `Client request failed: ${opts.error.message}\n\n${NETWORK_ERROR_TROUBLESHOOTING_MSG}`,
        'network_error',
        undefined,
        'error',
        { body: opts.body, method: opts.method, path: opts.path, reason: opts.error.message },
    );

    replicantError.stack = opts.error.stack;

    return replicantError;
}

type RequestOptions = {
    retryOptions?: Partial<RetryOptions>;
    defaultErrorCodes?: { code: ReplicantErrorCode; subCode: ReplicantErrorSubCode };
};
