import { ActionAnalyticsCallback, AnalyticsPayload } from '../common/Analytics';
import { FriendsStatesMap } from '../common/FriendsStatesMap';
import { Geolocale } from '../common/Geolocale';
import type { LoginInfo } from '../common/LoginInfo';
import {
    APNProps,
    FCMProps,
    PushNotificationPlatformProps,
    PushNotificationPlatformResponses,
    PushNotificationResponse,
} from '../common/PushNotifications';
import { MAX_CLIENT_SYNC_CLOCK_OFFSET } from '../common/ReplicantConstants';
import type { ExchangeRates, PaymentSubscriptionStatus } from '../common/Types';
import { Gender } from '../common/Types';
import type { ABTestsDynamicConfig, Entry } from '../db/DB';
import { ReplicantError, ReplicantErrorCode } from '../Errors';
import logger from '../logger';
import packageVersion from '../packageVersion';
import type { Action, ActionType } from '../ReplicantActions';
import { AsyncGetterInvokeFn, AsyncGettersCallables } from '../ReplicantAsyncGetters';
import { OnErrorHandler, Replicant, ReplicantConfig } from '../ReplicantConfig';
import { Message } from '../ReplicantMessages';
import type { ReplicantPlatform } from '../ReplicantPlatform';
import { WithMeta } from '../systemStateFields';
import { RetryOptions } from '../utils/AsyncUtils';
import { isAndroidDeviceTokenExpired, isAppleDeviceTokenExpired } from '../utils/DeviceTokenUtils';
import { encodeJS } from '../utils/ErrorUtils';
import type { DeepPartial, Immutable, Namespaced } from '../utils/TypeUtils';
import { isSerializable } from '../utils/Utils';
import ClientInternalKeyValueStore from './ClientInternalKeyValueStore';
import ClientKeyValueStore from './ClientKeyValueStore';
import { ClientReplicantABTests, ClientReplicantABTestsManager } from './ClientReplicantABTests';
import { ClientReplicantFriends, ClientReplicantFriendsManager } from './ClientReplicantFriends';
import { ClientReplicantOTP } from './ClientReplicantOTP';
import ClientReplicantQueue, { OnMessagesReceivedFn } from './ClientReplicantQueue';
import { ClientReplicantSocialGraph } from './ClientReplicantSocialGraph';
import ClientReplicantUserAssets from './ClientReplicantUserAssets';
import { loginOrCreateUser } from './LoginOrCreateUser';
import ReplicantHttpClient from './ReplicantHttpClient';

export type CommonReplicantConfiguration = {
    checkForMessagesInterval?: number;
    refreshFriendsStatesInterval?: number;
    batchingMaxTime?: number;
};

export type ReplicantConnectionOptions = CommonReplicantConfiguration & {
    /**
     * Replicant endpoint URL without the version string.
     *
     * @example 'https://my-app-name.us-east-1.replicant.gc-internal.net/my-app-name'
     */
    endpoint: string;

    /**
     * Optional session name identifier.
     *
     * Use session names to handle [`session_desync` errors](https://docs.dev.gc-internal.net/replicant/faq/#how-to-fix-session-desync-errors).
     * When configured, this value is included in `session_desync` errors as `clientProps.clientSessionName` and `clientProps.lastSessionName`.
     */
    sessionName?: string;

    /**
     * Platform authentication token or signature.
     *
     * On the LINE platform use either
     * - a LINE Game SDK user token (default), or
     * - a LINE Login API access token if `enableLineAccessTokenAuthentication` is enabled in Replicant configuration.
     */
    signature?: string;

    /**
     * Telegram authorization data, obtained from Link URL/Login Widget redirect URL query parameters or `Telegram.WebApp.initData`.
     *
     * @see https://core.telegram.org/widgets/login#receiving-authorization-data
     */
    telegramAuthorizationData?: {
        auth_date: string;
        first_name?: string;
        hash: string;
        id: string;
        last_name?: string;
        photo_url?: string;
        username?: string;
    };

    obtainSignature?: () => Promise<string>;
    fetchOverride?: typeof fetch;
};

export type ClientReplicantDevOpts = {
    dateNow?: () => number;
    clockSyncOffsetAllowedDifference?: number;
    maxRecentMessagesTime?: number;
    retryOptions?: RetryOptions;
};

// Configure replicant client how to access the replicant server.
export type InternalReplicantOptions = {
    clockOffset?: number;
    devOpts?: ClientReplicantDevOpts;
    isWebPlayable?: boolean;
    loginToken?: { token: string; userId: string };
    onLoginActionArgs?: any;
    platform: ReplicantPlatform;
} & ReplicantConnectionOptions;

export type ClientActionInvocationCallback = (action: string, args: any) => void;
export type ClientActionInvocationErrorCallback = (action: string, args: any, err: string) => void;

export type ActionInvokeFn<T extends Action<any>> = (
    args: Parameters<T['fn']>[1],
) => Promise<Awaited<ReturnType<T['fn']>>>;

export class ClientReplicant<T extends Replicant> {
    asyncGetters = {} as AsyncGettersCallables<T['asyncGetters']>;

    invoke = {} as {
        [K in keyof T['actions']]: T['actions'][K] extends Action<any>
            ? ActionInvokeFn<T['actions'][K]>
            : T['actions'][K] extends infer NestedT extends Record<string, any>
            ? { [NestedK in keyof NestedT]: ActionInvokeFn<NestedT[NestedK]> }
            : never;
    };

    /** Extra functionality mainly for analytics purposes. */
    extras = {
        /**
         * Get eCPM values grouped by ad placement ID. Values differ per requesting client's IP-based geolocation.
         *
         * @see https://docs.dev.gc-internal.net/gcinstant/ads/
         */
        getECPM: (): { fb: { perAdPlacement?: { [placementId: string]: number | undefined } } } => {
            return {
                fb: this.extraData.ecpmPerAdPlacement ? { perAdPlacement: this.extraData.ecpmPerAdPlacement } : {},
            };
        },

        /** Requires the [`EXCHANGE_RATES_API_ACCESS_KEY` environment variable](https://docs.dev.gc-internal.net/replicant/Environment/#app-level-variable-descriptions). */
        getExchangeRates: (): ExchangeRates => {
            return { ...this.extraData.exchangeRates };
        },

        /** @returns Analytics payload of the last platform chatbot message received before the current session start, or `undefined` in case of no received messages. */
        getLastReceivedChatbotMessagePayload: (): { [key: string]: unknown; timestamp: number } | undefined =>
            this.userData.chatbotMetainfo?.lastReceivedMessagePayload,

        /** Invoke the after context switch event hook. Called by replicant-gcinstant-extensions. */
        afterContextSwitch: async (opts: { contextSwitchEventId: string }): Promise<void> => {
            await this.httpClient.doPostAfterContextSwitchRequest({ contextSwitchEventId: opts.contextSwitchEventId });
        },

        /**
         * Registers a callback to be invoked when analytic events are
         * generated by action logic.
         *
         * @param handler The callback to be invoked.
         */
        setActionAnalyticsCallback: (handler: ActionAnalyticsCallback) => {
            this.queueManager.setActionAnalyticsCallback(handler);
        },
    };

    /**
     * One-time password login methods.
     *
     * Only enabled in web playable mode: OTP initiation and verification methods throw errors when web playable mode is not enabled.
     *
     * @see https://docs.dev.gc-internal.net/replicant/OneTimePassword/
     */
    otp: ClientReplicantOTP<T['otpTemplateId']>;

    /**
     * Methods for interacting with Stripe payment subscriptions.
     *
     * @see https://docs.dev.gc-internal.net/replicant/API/index.html#paymentsubscriptions
     */
    paymentSubscriptions = {
        /** @returns Payment subscription status or `undefined` in case of no subscription. */
        getStatus: (): PaymentSubscriptionStatus | undefined => {
            return this.userData.metainfoMVCC.paymentSubscription?.status;
        },
    };

    readonly platform: ReplicantPlatform;

    /** Methods for updating the social graph. Use `asyncGetters` for querying the social graph. */
    socialGraph: ClientReplicantSocialGraph;

    private friendsMngr: ClientReplicantFriends<T>;
    private abTestsManager: ClientReplicantABTests<T>;

    // If we fail a userstate refresh, retryLastRequest will retry that as well.
    private refreshToRetry = false;

    // Invoking actions is forbidden on the client when an error is received, because state is out of sync.
    private forbidInvocations = false;

    private onStateChangedHandlers: ((
        state: Immutable<WithMeta<T['state'], T['ruleset'], T['sharedStates']>>,
    ) => void)[] = [];

    private clockSyncOffset: number;
    private currentTime: number;

    private queueManager: ClientReplicantQueue<T>;
    private userAssets: ClientReplicantUserAssets;

    private paused = false;

    constructor(
        private id: string,
        readonly sessionId: string,
        private userData: Entry<T['state']>,
        readonly config: ReplicantConfig<T>,
        private options: InternalReplicantOptions,
        private httpClient: ReplicantHttpClient<T>,
        public kvStore: ClientKeyValueStore,
        public internalKvStore: ClientInternalKeyValueStore,
        private userAssetsBaseUrl: string,
        private chatbotAssetUrls: { [assetName in T['chatbotAssets']]: string },
        private extraData: LoginInfo['extraData'],
        abTestChangeEvents: LoginInfo['abTestChangeEvents'],
        abTestsDynamicConfig: ABTestsDynamicConfig,
    ) {
        // Wrap all actions.
        const invoke: Namespaced<ActionInvokeFn<any>> = {};

        for (const key in config.actions) {
            const value = config.actions[key]!;

            if (typeof value.fn === 'function' && typeof value.type === 'string') {
                const action = value as Action<any>;

                invoke[key] = this.wrapAction(key, action.type);
            } else {
                const namespaceActions = value as Record<string, Action<any>>;

                const namespaceInvoke: Record<string, ActionInvokeFn<any>> = {};

                for (const nestedKey in namespaceActions) {
                    const wrappedAction = this.wrapAction(`${key}.${nestedKey}`, namespaceActions[nestedKey]!.type);

                    namespaceInvoke[nestedKey] = wrappedAction;
                }

                invoke[key] = namespaceInvoke;
            }
        }

        this.invoke = invoke as typeof this.invoke;

        // Wrap all asyncGetters.
        const asyncGetters: AsyncGettersCallables<any> = {};

        const invokeAsyncGetter = (name: string, args: unknown): Promise<unknown> =>
            this.httpClient.doAsyncGetterRequest({
                args,
                name,
                sid: this.sessionId,
                consistentFetchIds: this.queueManager.calcConsistentFetchIds(),
            });

        for (const key in config.asyncGetters) {
            const value = config.asyncGetters[key];

            if (typeof value === 'function') {
                asyncGetters[key] = (args: unknown) => invokeAsyncGetter(key, args);
            } else {
                const namespace: Record<string, AsyncGetterInvokeFn<any>> = {};

                for (const nestedKey in value) {
                    namespace[nestedKey] = (args: unknown) => invokeAsyncGetter(`${key}.${nestedKey}`, args);
                }

                asyncGetters[key] = namespace;
            }
        }

        this.asyncGetters = asyncGetters;

        this.clockSyncOffset = this.options.clockOffset || 0;
        this.currentTime = this.userData.lastInvokeTime || 0;

        this.friendsMngr = new ClientReplicantFriends({
            config,
            options,
            httpClient: this.httpClient,
            adjustClockOffset: (...args) => this.adjustClockOffset(...args),
            calcConsistentFetchIds: () => this.queueManager.calcConsistentFetchIds(),
        });

        this.abTestsManager = new ClientReplicantABTests({
            config,
            dynamicConfig: abTestsDynamicConfig,
            metainfo: this.userData.metainfo,
            changeEvents: abTestChangeEvents,
        });

        // Initialize the local copy of chatbotMetainfo if uninitialized, so that changes via
        // the actions API are persisted locally (e.g. appleDeviceToken).
        userData.chatbotMetainfo = userData.chatbotMetainfo ?? {};

        this.otp = new ClientReplicantOTP({
            hasPhoneNumber: () => !!this.userData.metainfo.hasPhoneNumber,
            httpClient: this.httpClient,
        });

        this.platform = options.platform;

        // Heavy lifting done in the replicant queue manager for state management and actions enqueuing.
        this.queueManager = new ClientReplicantQueue({
            abTestsDynamicConfig,
            getTrackedUserIds: () => this.friends.getTrackedUserIds(),
            id,
            sessionId: this.sessionId,
            userData,
            config,
            replicantOptions: options,
            httpClient: this.httpClient,
            now: () => this.now() - (this.userData.metainfo.clockOffset || 0),
            adjustClockOffset: (...args) => this.adjustClockOffset(...args),
            hooks: {
                onStateChanged: (state) => this.handleOnStateChanged(state),
                onActionCompleted: (batchId) => this.handleActionCompleted(batchId),
                onMessagePosted: (msgId, message) => this.onMessagePosted(msgId, message),
                onReplicationResultStates: (states, batchId) => {
                    this.friendsMngr.handleReplicationResultStates(states, batchId);
                },
                onError: (err) => {
                    if (err.code !== ReplicantErrorCode.network_error) {
                        this.friendsMngr.clearLocks();
                        this.pause();
                        this.forbidInvocations = true;
                    }

                    this.onError(err);
                },
            },
            userAssetsBaseUrl,
            abTestsManager: this.abTestsManager,
        });

        this.socialGraph = new ClientReplicantSocialGraph(this.httpClient, () => this.now());
        this.userAssets = new ClientReplicantUserAssets(this.httpClient);

        if (config.onError) {
            this.setOnError(config.onError);
        }
    }

    get state(): WithMeta<Immutable<T['state']>, T['ruleset'], T['sharedStates']> {
        return this.queueManager.getExternalState();
    }

    get userId() {
        return this.id;
    }

    /** @deprecated Use async getters to look up other players' state properties. */
    get friends(): ClientReplicantFriendsManager<T['state'], T['ruleset']> {
        return this.friendsMngr;
    }

    get abTests(): ClientReplicantABTestsManager<T> {
        return this.abTestsManager;
    }

    getChatbotSessionData(): { sessionId: string; appVersion: string } {
        return {
            sessionId: this.sessionId,
            appVersion: this.config.version,
        };
    }

    onStateChanged(fn: (state: Immutable<WithMeta<T['state'], T['ruleset'], T['sharedStates']>>) => void) {
        this.onStateChangedHandlers.push(fn);
    }

    removeStateChangedHandler(fn: (state: Immutable<WithMeta<T['state'], T['ruleset'], T['sharedStates']>>) => void) {
        this.onStateChangedHandlers = this.onStateChangedHandlers.filter((x) => x !== fn);
    }

    clearStateChangedHandlers() {
        this.onStateChangedHandlers = [];
    }

    // onMessagesReceived handlers for notifying the client when the server has new messages
    // Implementation-wise they're forwarded to the queueManager, as it deals with replication.
    onMessagesReceived(fn: OnMessagesReceivedFn<T>) {
        this.queueManager.onMessagesReceived(fn);
    }

    removeMessagesReceivedHandler(fn: OnMessagesReceivedFn<T>) {
        this.queueManager.removeMessagesReceivedHandler(fn);
    }

    //

    /**
     * Register a handler to be invoked when there is a replication error.
     *
     * Overwrites `config.onError`.
     *
     * @see https://docs.dev.gc-internal.net/replicant/index.html#error-handling
     */
    setOnError(errorHandler: OnErrorHandler) {
        this.onError = errorHandler;
    }

    // Pause / resume

    pause() {
        this.paused = true;

        this.queueManager.pause();
        this.friendsMngr.pause();
        this.httpClient.pause();
    }

    resume(opts?: { skipCheckForMessages?: boolean }) {
        if (this.forbidInvocations) {
            return;
        }

        this.paused = false;

        this.queueManager.resume();
        this.friendsMngr.resume();
        this.httpClient.resume();

        // Check for messages and also resync clcok
        if (!opts?.skipCheckForMessages) {
            void this.checkForMessages();
        }
    }

    isPaused() {
        return this.paused;
    }

    /**
     * Fetch player states from the server. Returns a map of player entries indexed by player ID.
     *
     * Note that since `fetchStates` fetches and returns entire player state objects, it is far more efficient to use `asyncGetters` to fetch a subset of player state instead.
     *
     * Stores and returns a default player state for any missing player ID in `ids`.
     */
    async fetchStates(ids: string[]): Promise<FriendsStatesMap<T['state'], T['ruleset'], T['sharedStates']>> {
        return this.friendsMngr.fetchOtherPlayerStates(ids);
    }

    // Waits until the whole current queue is flushed.
    async waitForEmptyQueue(): Promise<void> {
        return this.queueManager.waitForEmptyQueue();
    }

    // Wait for the current replication queue to flush.
    async flush(opts?: { skipErrorHandlers?: boolean }) {
        return this.queueManager.flush(opts);
    }

    now() {
        this.currentTime = Math.max(this.currentTime, Math.floor(this.dateNow() + this.clockSyncOffset));
        return this.currentTime + (this.userData.metainfo.clockOffset || 0);
    }

    adjustClockOffset(newOffset: number) {
        // Default max allowed is half the allowed clock offset on the server.
        const maxDifference =
            this.options.devOpts?.clockSyncOffsetAllowedDifference || MAX_CLIENT_SYNC_CLOCK_OFFSET / 2;
        const difference = Math.abs(this.now() - (this.dateNow() + newOffset));

        if (difference > maxDifference) {
            this.clockSyncOffset = newOffset;
        }
    }

    checkForMessages() {
        if (this.forbidInvocations) {
            return Promise.reject(
                new ReplicantError(
                    `Do not check for messages while out of sync. Refresh first!`,
                    'replication_error',
                    'invoking_while_out_of_sync',
                ),
            );
        }

        return this.queueManager.checkForMessages();
    }

    async setTestUsers(states: { [idSuffix: string]: DeepPartial<T['state']> }) {
        const result = await this.httpClient.doPostTestUsers({ states });
        return result.data.testUsersMap;
    }

    getPurchaseHistory() {
        return this.queueManager.getPurchaseHistory();
    }

    /** @returns Authentication token issued by Replicant backend. */
    getToken(): Promise<string> {
        return this.httpClient.getToken();
    }

    /**
     * @param name Japanese name, either in hiragana, katakana or latin alphabet.
     * If name contains multiple whitespace-separated fragments, the fragment with the highest inference score is used.
     */
    async inferGenderFromName(name: string): Promise<Gender> {
        const response = await this.httpClient.doPostInferGenderFromName({ name });
        return response.data;
    }

    /**
     * @param newSignature Replaces old authentication token for this and all future requests.
     */
    async refresh(newSignature?: string): Promise<void> {
        this.friendsMngr.clearLocks();

        this.queueManager.resetQueue();

        // In case there are any running operations, wait until they flush, so we don't have race conditions.
        await this.flush({ skipErrorHandlers: true });

        if (newSignature) {
            this.httpClient.setSignature(newSignature);
        }

        let loginData: { data: Entry<T['state']>; clockOffset: number };
        try {
            loginData = await loginOrCreateUser<T>({
                httpClient: this.httpClient,
                devOpts: this.options.devOpts,
                sessionId: this.sessionId,
                skipOnLoginAction: true,
                isWebPlayable: this.options.isWebPlayable,
            });
        } catch (e: any) {
            this.refreshToRetry = true;
            this.onError(e);
            throw e;
        }

        this.forbidInvocations = false;

        // Clear retry flag for user refresh and automatic retry.
        this.refreshToRetry = false;

        // Snap the clock offset on refresh. We stamp the whole state anyway, so we don't need to worry
        // about time jumping.
        this.clockSyncOffset = loginData.clockOffset;
        // Restamp current time. If the user's clock jumped too far into the future,
        // we need to allow it to go back in order to compensate.
        this.currentTime = loginData.data.lastInvokeTime || 0;

        this.queueManager.onRefresh(loginData.data);

        // Resume client, but do not check for messages, since we just logged-in.
        this.resume({ skipCheckForMessages: true });
    }

    async retryLastRequest() {
        let result: boolean;
        // If there is a refresh request to retry, do so
        if (this.refreshToRetry) {
            await this.refresh();
            result = true;
        } else {
            result = await this.queueManager.retryLastRequest();
        }

        this.friendsMngr.goOnline();

        return result;
    }

    async uploadUserAsset(dataUrl: string): Promise<string> {
        return this.userAssets.upload(dataUrl);
    }

    async sendIOSPushNotification(opts: {
        receiverId: string;
        notification: APNProps;
        payload: AnalyticsPayload;
        imageUrl?: string;
        analyticsUserProps?: { [key: string]: unknown };
    }): Promise<PushNotificationResponse> {
        const result = await this.httpClient.doPostAPN(opts);
        return result.data;
    }

    /** @returns The user's login-time Apple device token. Does not account for device tokens set mid-session. */
    getAppleDeviceToken(): string | undefined {
        const tokenExpired = this.userData.chatbotMetainfo?.appleDeviceTokenUpdatedAt
            ? isAppleDeviceTokenExpired(this.userData.chatbotMetainfo.appleDeviceTokenUpdatedAt, this.dateNow())
            : false;

        return !tokenExpired ? this.userData.chatbotMetainfo?.appleDeviceToken : undefined;
    }

    async sendAndroidPushNotification(opts: {
        receiverId: string;
        notification: FCMProps;
        payload: AnalyticsPayload;
        imageUrl?: string;
        analyticsUserProps?: { [key: string]: unknown };
    }): Promise<PushNotificationResponse> {
        const result = await this.httpClient.doPostAndroidPushNotification(opts);
        return result.data;
    }

    /** @returns The user's login-time Android device token. Does not account for device tokens set mid-session. */
    getAndroidDeviceToken(): string | undefined {
        const tokenExpired = this.userData.chatbotMetainfo?.androidDeviceTokenUpdatedAt
            ? isAndroidDeviceTokenExpired(this.userData.chatbotMetainfo.androidDeviceTokenUpdatedAt, this.dateNow())
            : false;

        return !tokenExpired ? this.userData.chatbotMetainfo?.androidDeviceToken : undefined;
    }

    async sendPushNotification(opts: {
        receiverId: string;
        notification:
            | { text: { title: string; body: string } }
            | { overrideDefaultProps: PushNotificationPlatformProps };
        payload: AnalyticsPayload;
        imageUrl?: string;
        analyticsUserProps?: { [key: string]: unknown };
    }): Promise<PushNotificationPlatformResponses> {
        const { notification, ...restOpts } = opts;
        let notificationsPayload;
        if ('text' in notification) {
            // Default notification settings
            const {
                text: { title: notificationTitle, body: notificationBody },
            } = notification;
            notificationsPayload = {
                ios: {
                    alert: { title: notificationTitle, body: notificationBody },
                    sound: 'default',
                },
                android: { title: notificationTitle, body: notificationBody, icon: 'ic_launcher', sound: 'default' },
            };
        } else {
            // Override default notification settings
            notificationsPayload = notification.overrideDefaultProps;
        }

        const result = await this.httpClient.doPostPushNotification({
            notifications: notificationsPayload,
            ...restOpts,
        });
        return result.data;
    }

    getNativeBridgeSecret() {
        return this.userData.chatbotMetainfo?.nativeBridgeSecret;
    }

    getGeolocation(): Geolocale | undefined {
        return this.extraData.geolocation;
    }

    getReplicantVersion(): string {
        return packageVersion;
    }

    /**
     *
     * @param assetId Identifier of a user uploaded asset. This is obtained from the return value of uploadUserAsset(dataUrl: string)
     * @returns The full URL for a user-uploaded asset.
     */
    getUserAssetUrl(assetId: string): string {
        return this.userAssetsBaseUrl + assetId;
    }

    /**
     * @param assetName One of the chatbot asset names passed to `renderTemplatesWithAssets`.
     * @returns The full URL for a pre-uploaded chatbot asset.
     */
    getChatbotAssetUrl(assetName: keyof T['chatbotAssets']): string {
        return this.chatbotAssetUrls[assetName];
    }

    setActionPreInvokeCallback(callback: ClientActionInvocationCallback) {
        this.queueManager.actionInvocationCallbacks.preInvoke = callback;
    }

    setActionPostInvokeCallback(callback: ClientActionInvocationCallback) {
        this.queueManager.actionInvocationCallbacks.postInvoke = callback;
    }

    setActionCancelledCallback(callback: ClientActionInvocationCallback) {
        this.queueManager.actionInvocationCallbacks.cancelled = callback;
    }

    setActionFailedCallback(callback: ClientActionInvocationErrorCallback) {
        this.queueManager.actionInvocationCallbacks.failed = callback;
    }

    private handleOnStateChanged(state: WithMeta<T['state'], T['ruleset'], T['sharedStates']>) {
        this.onStateChangedHandlers.forEach((fn) =>
            fn(state as Immutable<WithMeta<T['state'], T['ruleset'], T['sharedStates']>>),
        );
    }

    private onError: OnErrorHandler = (err) => {
        throw new Error(`{message: ${err.message}, code: ${err.code} }`);
    };

    private wrapAction(key: string, type: ActionType): ActionInvokeFn<any> {
        return (args: any) => {
            let isProduction = false;
            try {
                isProduction = process.env.STAGE === 'prod';
            } catch {
                // Ignore errors on undefined `process.env` to handle builds without DefinePlugin
            }

            if (!isProduction && args !== undefined && !isSerializable(args)) {
                logger.warn(
                    [
                        `Action ${key} was called with an argument that can't be sent to the server.`,
                        'Argument: ' + encodeJS(args, 4),
                        'Would be sent as: ' + JSON.stringify(args, null, 4),
                    ].join('\n'),
                );
                logger.warn('Above Warning may become an EXCEPTION in future release, please fix it!');
            }

            if (this.forbidInvocations) {
                return Promise.reject(
                    new ReplicantError(
                        `Trying to invoke ${key} while state is out of sync. Refresh first!`,
                        'replication_error',
                        'invoking_while_out_of_sync',
                    ),
                );
            }

            return this.queueManager.enqueueAction(key, type, args);
        };
    }

    private onMessagePosted = (id: string, message: Message) => {
        // Store the message.
        this.friendsMngr.handleReplicantMessage(id, message);
    };

    private handleActionCompleted = (batchId: string) => {
        // Optimistically apply to local friends states and record that we want to get the friend's state.
        this.friendsMngr.handleReplicantActionCompleted(batchId);
        this.abTestsManager.handleReplicantActionCompleted();
    };

    private dateNow() {
        return this.options.devOpts?.dateNow?.() || Date.now();
    }
}
