export default interface ServerAssetUploader {
    uploadDataURLAsset(id: string, dataUrl: string): Promise<string>;
}

export function parseAssetDataUrl(dataUrl: string) {
    const mediaType = parseAndValidateMediaType(dataUrl);
    const extension = mediaType === 'image/jpeg;base64' ? 'jpg' : 'png';

    return {
        b64: dataUrl.substr(dataUrl.indexOf(',') + 1),
        mediaType,
        extension,
    };
}

export function parseAndValidateMediaType(dataUrl: string): string {
    if (!dataUrl.startsWith('data:')) {
        throw new APIError('Invalid dataUrl');
    }

    const mediaType = dataUrl.substring(dataUrl.indexOf(':') + 1, dataUrl.indexOf(','));

    if (mediaType !== 'image/jpeg;base64' && mediaType !== 'image/png;base64') {
        throw new APIError('Unsupported media type: ' + mediaType);
    }

    return mediaType;
}

class APIError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'APIError';
    }
}
