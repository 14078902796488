import { ARGS_TOO_LARGE_WARNING_LIMIT_BYTES } from './common/ReplicantConstants';

export const ReplicantErrorCode = {
    network_error: 'network_error',
    replication_error: 'replication_error',
    version_error: 'version_error',
    session_desync_error: 'session_desync_error',
    authorization_error: 'authorization_error',
    server_error: 'server_error',
    unknown_error: 'unknown_error',
} as const;

export type ReplicantErrorCode = keyof typeof ReplicantErrorCode;

export const AuthErrorSubCode = {
    audience_mismatch: 'audience_mismatch',
    id_mismatch: 'id_mismatch',
    invalid_platform: 'invalid_platform',
    invalid_otp_code: 'invalid_otp_code',
    invalid_otp_receiver: 'invalid_otp_receiver',
    invalid_scope: 'invalid_scope',
    invalid_signature: 'invalid_signature',
    token_expired: 'token_expired',
};

export type AuthErrorSubCode = keyof typeof AuthErrorSubCode;

export const ReplicantErrorSubCode = {
    ...AuthErrorSubCode,
    action_args_too_large: 'action_args_too_large',
    analytics_extra_properties_error: 'analytics_extra_properties_error',
    db_error: 'db_error',
    exceeded_message_reduction_threshold: 'exceeded_message_reduction_threshold',
    gender_inference_error: 'gender_inference_error',
    inapplicable_diff: 'inapplicable_diff',
    index_doc_too_large: 'index_doc_too_large',
    invalid_ecpm: 'invalid_ecpm',
    invalid_state: 'invalid_state',
    invalid_action: 'invalid_action',
    invalid_message: 'invalid_message',
    invalid_message_args: 'invalid_message_args',
    invalid_phone_number: 'invalid_phone_number',
    lambda_high_memory_usage: 'lambda_high_memory_usage',
    lambda_timeout: 'lambda_timeout',
    message_args_too_large: 'message_args_too_large',
    missing_messages: 'missing_messages',
    client_time_invalid: 'client_time_invalid',
    client_time_offset_too_large: 'client_time_offset_too_large',
    random_seed_invalid: 'random_seed_invalid',
    message_errored: 'message_errored',
    async_action_api_error: 'async_action_api_error',
    delayed_actions_error: 'delayed_actions_error',
    async_getter_error: 'async_getter_error',
    payment_signature_verification_error: 'payment_signature_verification_error',
    payment_already_purchased_error: 'payment_already_purchased_error',
    scheduled_action_args_too_large: 'scheduled_action_args_too_large',
    shared_state_too_large: 'shared_state_too_large',
    unknown_message: 'unknown_message',
    user_asset_upload_error: 'user_asset_upload_error',
    scheduled_actions_error: 'scheduled_actions_error',
    key_value_storage_error: 'key_value_storage_error',
    admin_interface_error: 'admin_interface_error',
    user_login_error: 'user_login_error',
    payload_too_large: 'payload_too_large',
    state_too_large: 'state_too_large',
    rev_id_mismatch: 'rev_id_mismatch',
    request_replayed: 'request_replayed',
    retry_queue_mismatch: 'retry_queue_mismatch',
    invoking_while_out_of_sync: 'invoking_while_out_of_sync',
    push_notification_error: 'push_notification_error',
    search_error: 'search_error',
    ab_tests_error: 'ab_tests_error',

    chatbot_receiver_not_found: 'chatbot_receiver_not_found',
    chatbot_missing_player: 'chatbot_missing_player',
    chatbot_missing_receiver: 'chatbot_missing_receiver',
    chatbot_missing_sender: 'chatbot_missing_sender',
    chatbot_missing_configuration: 'chatbot_missing_configuration',
    chatbot_missing_asset: 'chatbot_missing_asset',
    chatbot_missing_asset_url: 'chatbot_missing_asset_url',
    chatbot_send_error: 'chatbot_send_error',
    fb_comment_retrieval_error: 'fb_comment_retrieval_error',

    webhook_authorization_failed: 'webhook_authorization_failed',
    webhook_invalid_event: 'webhook_invalid_event',
    webhook_referral_url_not_set: 'webhook_referral_url_not_set',
    webhook_referral_player_not_found: 'webhook_referral_player_not_found',
    webhook_postback_missing_params: 'webhook_postback_missing_params',

    payment_subscription_invalid_otp: 'payment_subscription_invalid_otp',
} as const;

export type ReplicantErrorSubCode = keyof typeof ReplicantErrorSubCode;

export class ReplicantError extends Error {
    /**
     * @param extras Extra properties attached to Sentry reports and omitted from production client errors.
     * @param clientExtras Extra properties attached to Sentry reports and included in production client errors.
     */
    constructor(
        message: string,
        public code: ReplicantErrorCode,
        public subCode?: ReplicantErrorSubCode,
        public severity?: 'warning' | 'error' | 'fatal',
        public extras?: { [key: string]: any },
        public clientExtras?: {
            /** The current client's `sessionName` option. Populated in case of `session_desync` errors. */
            clientSessionName?: string;

            /** The `sessionName` option last used by the current user. Populated in case of `session_desync` errors. */
            lastSessionName?: string;
        },
    ) {
        super(message);
        this.name = 'ReplicantError';
    }
}

export function createActionArgsTooLargeWarning(opts: { actionName: string; argsSize: number }): ReplicantError {
    return new ReplicantError(
        `Action arguments size exceeds ${Math.round(
            ARGS_TOO_LARGE_WARNING_LIMIT_BYTES / 1000,
        )} KB. Reduce arguments size to improve performance and to avoid hitting the database item limit.`,
        'server_error',
        'action_args_too_large',
        'warning',
        opts,
    );
}

export function createScheduledActionArgsTooLargeWarning(opts: {
    argsSize: number;
    scheduledActionName: string;
}): ReplicantError {
    return new ReplicantError(
        `Scheduled action arguments size exceeds ${Math.round(
            ARGS_TOO_LARGE_WARNING_LIMIT_BYTES / 1000,
        )} KB. Reduce arguments size to improve performance and to avoid hitting the database item limit.`,
        'server_error',
        'scheduled_action_args_too_large',
        'warning',
        opts,
    );
}

export function createIndexDocTooLargeWarning(opts: {
    docSize: number;
    sharedStateId?: string;
    userId?: string;
}): ReplicantError {
    return new ReplicantError(
        'Index document size exceeds 200 KB. Reduce index document size to improve performance and to avoid hitting the 256KB hard limit.',
        'server_error',
        'index_doc_too_large',
        'warning',
        {
            description:
                'To reduce index document size either a) remove unused computed properties or b) shrink the indexed property values, for example by filter out old items in case of array properties.',
            docSize: opts.docSize,
            sharedStateId: opts.sharedStateId,
            userId: opts.userId,
        },
    );
}
