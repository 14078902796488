import { deepEquals } from '../utils/ObjUtils';

export function generateRandomId(random = Math.random): string {
    return random().toString(36).substr(2, 9);
}

export function compareVersions(a: string, b: string) {
    const semVerRE = /(\d+)\.(\d+)\.(\d+)/;

    // A version that doesn't match the regex must be newer.
    if (!semVerRE.test(a)) return -1;
    if (!semVerRE.test(b)) return 1;

    const [aMajor, aMinor, aPatch] = a.match(semVerRE)!.slice(1, 4).map(Number) as [number, number, number];
    const [bMajor, bMinor, bPatch] = b.match(semVerRE)!.slice(1, 4).map(Number) as [number, number, number];

    if (aMajor === bMajor && aMinor === bMinor && aPatch === bPatch) {
        return 0;
    }

    return aMajor - bMajor || aMinor - bMinor || aPatch - bPatch;
}

export function getContentTypeForImage(filename: string) {
    const extension = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();

    let contentType: string;

    if (extension === 'jpg' || extension === 'jpeg') {
        contentType = 'image/jpeg';
    } else if (extension === 'png') {
        contentType = 'image/png';
    } else if (extension === 'gif') {
        contentType = 'image/gif';
    } else if (extension === 'mp4') {
        contentType = 'video/mp4';
    } else {
        throw new Error('Unsupported image extension: ' + extension);
    }

    return contentType;
}

export function getStage(): string | undefined {
    try {
        return process.env.STAGE;
    } catch {
        // Ignore errors on undefined `process.env` to handle builds without DefinePlugin
        return undefined;
    }
}

export function isPromise(obj: unknown): obj is Promise<unknown> {
    return !!obj && typeof obj === 'object' && 'then' in obj && typeof obj.then === 'function';
}

export function unreachable(value: never): never {
    // We throw an error in case the TS type of `value` is different from its run-time type.
    throw new Error(`Invalid value: '${value as any}'`);
}

export function duration({ days = 0, hours = 0, minutes = 0, seconds = 0, ms = 0 }) {
    const second = 1000;
    const minute = 60 * second;
    const hour = 60 * minute;
    const day = 24 * hour;

    return ms + seconds * second + minutes * minute + hours * hour + days * day;
}

export function isSerializable(value: unknown): boolean {
    let parsedValue;
    try {
        parsedValue = JSON.parse(JSON.stringify(value));
    } catch {
        // JSON serialization may fail in edgy cases, in that case, false it is
        return false;
    }
    return deepEquals(parsedValue, value);
}

export function generateNumericString(n: number) {
    return new Array(n)
        .fill(0)
        .map(() => {
            // 0-9
            return Math.floor(Math.random() * 10);
        })
        .join('');
}

export function stringToUint8Array(str: string): Uint8Array {
    return new Uint8Array(Array.from(str).map((ch) => ch.charCodeAt(0)));
}

export function uint8ArrayToString(uint8Array: Uint8Array): string {
    return Array.from(uint8Array)
        .map((byte) => String.fromCharCode(byte))
        .join('');
}
