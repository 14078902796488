import { duration } from './Utils';

export const DEFAULT_APPLE_DEVICE_TOKEN_EXPIRATION_IN_MS = duration({ days: 30 });

export const DEFAULT_ANDROID_DEVICE_TOKEN_EXPIRATION_IN_MS = Infinity;

export function isAppleDeviceTokenExpired(appleDeviceTokenUpdatedAt: number, now: number): boolean {
    let expiryInMS = DEFAULT_APPLE_DEVICE_TOKEN_EXPIRATION_IN_MS;
    try {
        expiryInMS = Number(process.env.APPLE_DEVICE_TOKEN_EXPIRY_IN_MS) || DEFAULT_APPLE_DEVICE_TOKEN_EXPIRATION_IN_MS;
    } catch {
        // Do nothing and default to DEFAULT_APPLE_DEVICE_TOKEN_EXPIRY_IN_MS
    }
    return appleDeviceTokenUpdatedAt + expiryInMS < now;
}

export function isAndroidDeviceTokenExpired(androidDeviceTokenUpdatedAt: number, now: number): boolean {
    let expiryInMS = DEFAULT_ANDROID_DEVICE_TOKEN_EXPIRATION_IN_MS;
    try {
        expiryInMS =
            Number(process.env.ANDROID_DEVICE_TOKEN_EXPIRY_IN_MS) || DEFAULT_ANDROID_DEVICE_TOKEN_EXPIRATION_IN_MS;
    } catch {
        // Do nothing and default to DEFAULT_ANDROID_DEVICE_TOKEN_EXPIRY_IN_MS
    }
    return androidDeviceTokenUpdatedAt + expiryInMS < now;
}
