import type { OTPRequestOpts, OTPRequestResponse } from '../common/OTP';
import type ReplicantHTTPClient from './ReplicantHttpClient';

export class ClientReplicantOTP<TemplateID extends string> {
    constructor(
        private opts: {
            hasPhoneNumber: () => boolean;
            httpClient: ReplicantHTTPClient;
        },
    ) {}

    /** @returns `true` if user is linked to a verified phone number. */
    hasPhoneNumber(): boolean {
        return this.opts.hasPhoneNumber();
    }

    /**
     * Send a one-time password to given phone number to initiate phone number linking.
     *
     * @returns An object with the verification code ID and phone number info.
     *
     * @throws `subCode: 'invalid_phone_number'` If phone number is invalid.
     */
    async initiateOtp(
        opts: OTPRequestOpts<TemplateID> & {
            /**
             * The exact text used in the UI phone number prompt, stored for legal purposes.
             *
             * @example "Enter phone number for OTP login."
             */
            consentText: string;
        },
    ): Promise<OTPRequestResponse> {
        return this.opts.httpClient.doPostOtpInitiateAddReceiver(opts);
    }
}
