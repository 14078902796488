import { teaHash } from './math/prng/prng';
import SB from './SchemaBuilder';
import { partition } from './utils/ArrayUtils';
import { Immutable } from './utils/TypeUtils';

const abTestSchema = SB.object({
    newUsersOnly: SB.boolean().optional(),
    assignManually: SB.boolean().optional(),

    assignIf: SB.unknown().optional(), // Needed to appease the runtime validator

    dependsOn: SB.map(SB.array(SB.string())).optional(),

    buckets: SB.array(
        SB.object({
            id: SB.string(),
            weight: SB.number().min(0).optional(),
        }),
    )
        .minLength(1)
        .customValidator((buckets) => {
            const [nonWeightedBuckets, weightedBuckets] = partition(
                buckets,
                (bucket) => !!bucket && bucket.weight === undefined,
            );

            if (nonWeightedBuckets.length && weightedBuckets.length) {
                return 'Cannot mix weighted and non-weighted buckets';
            }

            return null;
        }),
});

export const rulesetSchema = SB.object({
    abTests: SB.map(abTestSchema).optional(),
});

export type Ruleset = Immutable<{
    abTests?:
        | Record<
              string,
              SB.ExtractType<typeof abTestSchema> & {
                  assignIf?: ((userState: any) => boolean) | undefined;
              }
          >
        | undefined;
}>;

export type EmptyRuleset = { abTests?: {} };

export type ABTestsConfig = Required<Ruleset>['abTests'];

/**
 * Usage:
 *
 * ```ts
 * const abTests = { myTest: { buckets: [{ id: 'control' }, { id: 'enabled' }] } } satisfies ABTestsConfig;
 *
 * type Bucket<T extends keyof typeof abTests> = ABTestBucket<typeof abTests, T>;
 * type MyTestBucket = Bucket<'myTest'>; // 'control' | 'enabled'
 * ```
 */
export type ABTestBucket<
    TABTestsConfig extends ABTestsConfig,
    TABTestID extends keyof TABTestsConfig,
> = TABTestsConfig[TABTestID]['buckets'][number]['id'] & string;

export function extendRulesetWithDefaults<T extends Ruleset | undefined>(ruleset: T): T {
    const DEFAULT_PLACEBO_AB_TESTS_CONFIG = {
        '0000_placebo_2': { buckets: [{ id: 'a' }, { id: 'b' }] },
        '0000_placebo_3': { buckets: [{ id: 'a' }, { id: 'b' }, { id: 'c' }] },
        '0000_placebo_4': { buckets: [{ id: 'a' }, { id: 'b' }, { id: 'c' }, { id: 'd' }] },
        '0000_placebo_5': { buckets: [{ id: 'a' }, { id: 'b' }, { id: 'c' }, { id: 'd' }, { id: 'e' }] },
    } satisfies ABTestsConfig;

    return {
        ...ruleset,
        abTests: { ...DEFAULT_PLACEBO_AB_TESTS_CONFIG, ...ruleset?.abTests },
    };
}

export function pickABTestBucket<TABTestsConfig extends ABTestsConfig, TABTestID extends keyof TABTestsConfig & string>(
    userId: string,
    testId: TABTestID,
    config: TABTestsConfig,
): ABTestBucket<TABTestsConfig, TABTestID> {
    const buckets = config[testId]!.buckets;
    const isWeightedTest = buckets[0]!.weight !== undefined;

    const roll = teaHash(`${userId}_${testId}`, 0);

    if (isWeightedTest) {
        const weightsSum = buckets.reduce((acc, bucket) => acc + bucket.weight!, 0);
        let weightedRoll = roll * weightsSum;

        for (let i = buckets.length - 1; i >= 0; i--) {
            const bucket = buckets[i]!;
            weightedRoll -= bucket.weight!;

            if (weightedRoll <= 0) {
                return bucket.id;
            }
        }

        return buckets[0]!.id; // Needed just to avoid the `function lacks ending return statement` type error
    } else {
        const index = Math.floor(roll * buckets.length);

        return buckets[index]!.id;
    }
}
