import { Replicant, ReplicantConfig } from '../ReplicantConfig';
import { OnlinePlatform } from '../ReplicantPlatform';
import { ReplicantConnectionOptions } from './ClientReplicant';
import { createOfflineReplicant, OfflineReplicantOpts } from './OfflineReplicantFactory';
import { createOnlineReplicant, OnlineReplicantOpts } from './OnlineReplicantFactory';
import { OnLoginActionArgs } from './createReplicant';

export type ReplicantOpts = OfflineReplicantOpts & ReplicantConnectionOptions;

/** Factory for online & offline replicant clients. */
export function createClientReplicant<T extends Replicant>(
    config: ReplicantConfig<T>,
    id: string,
    opts: ReplicantOpts & OnLoginActionArgs<T>,
) {
    const onLoginActionOpts = { onLoginActionArgs: (opts as any).onLoginActionArgs } as OnLoginActionArgs<T>;

    try {
        if (process.env.REPLICANT_OFFLINE) {
            const offlineOpts: OfflineReplicantOpts = {
                platform: opts.platform,
                storageKeyPrefix: opts.storageKeyPrefix,
                kvStore: opts.kvStore,
                debug: opts.debug,
                devOpts: opts.devOpts,
                latency: opts.latency,
                storageOverride: opts.storageOverride,
                createResponseOverride: opts.createResponseOverride,
                checkForMessagesInterval: opts.checkForMessagesInterval,
                refreshFriendsStatesInterval: opts.refreshFriendsStatesInterval,
                batchingMaxTime: opts.batchingMaxTime,
            };

            return createOfflineReplicant(config, id, { ...offlineOpts, ...onLoginActionOpts });
        } else {
            const onlineOpts: OnlineReplicantOpts = {
                platform: opts.platform as OnlinePlatform, // Validated in createOnlineReplicant.
                kvStore: opts.kvStore,
                debug: opts.debug,
                devOpts: opts.devOpts,
                endpoint: opts.endpoint,
                signature: opts.signature,
                telegramAuthorizationData: opts.telegramAuthorizationData,
                obtainSignature: opts.obtainSignature,
                fetchOverride: opts.fetchOverride,
                checkForMessagesInterval: opts.checkForMessagesInterval,
                refreshFriendsStatesInterval: opts.refreshFriendsStatesInterval,
                batchingMaxTime: opts.batchingMaxTime,
            };

            return createOnlineReplicant(config, id, { ...onlineOpts, ...onLoginActionOpts });
        }
    } catch (e) {
        throw new Error('process.env.REPLICANT_OFFLINE not accessible, cannot use this method');
    }
}
