import { LoginInfo, LoginInfoWebPlayer } from '../common/LoginInfo';
import type { OTPVerificationOpts } from '../common/OTP';
import { Entry } from '../db/DB';
import { ReplicantError, ReplicantErrorCode } from '../Errors';
import { Replicant } from '../ReplicantConfig';
import { ClientReplicantDevOpts } from './ClientReplicant';
import ReplicantHttpClient from './ReplicantHttpClient';

export async function loginOrCreateUser<T extends Replicant>(opts: {
    clientAppName?: string;
    httpClient: ReplicantHttpClient<T>;
    isWebPlayable?: boolean;
    devOpts: ClientReplicantDevOpts | undefined;
    loginToken?: { token: string; userId: string };
    otp?: OTPVerificationOpts;
    sessionId: string;
    sessionName?: string;
    onLoginActionArgs?: any;
    prefetchInternalKeys?: string[];
    prefetchKeys?: string[];
    skipOnLoginAction?: boolean;
}): Promise<{ data: Entry<T['state']> & (LoginInfo | LoginInfoWebPlayer); clockOffset: number }> {
    const {
        clientAppName,
        devOpts,
        httpClient,
        isWebPlayable,
        loginToken,
        onLoginActionArgs,
        otp,
        prefetchKeys,
        prefetchInternalKeys,
        sessionId,
        sessionName,
        skipOnLoginAction,
    } = opts;

    const now = devOpts?.dateNow ?? Date.now;

    const timeStart = now(); // measure latency

    const loginMethod = isWebPlayable ? 'doLoginOrCreateWebPlayerRequest' : 'doLoginOrCreateRequest';
    const result = await httpClient[loginMethod]({
        sessionName,
        sid: sessionId,
        skipOnLoginAction,
        prefetchKeys,
        prefetchInternalKeys,
        onLoginActionArgs,
        ...(isWebPlayable ? { clientAppName, loginToken, otp } : {}),
    });

    if (!result || !result.data) {
        throw new ReplicantError('Malformed result.', ReplicantErrorCode.unknown_error);
    }

    const timeEnd = now();

    const clockOffset = computeClockOffset(timeStart, timeEnd, result);

    return {
        data: result.data,
        clockOffset,
    };
}

export function computeClockOffset(
    clientTimeStart: number,
    clientTimeEnd: number,
    serverResult: { t: number; service: number },
) {
    if (!serverResult.t || typeof serverResult.service === 'undefined') {
        throw new Error('Malformed server result: missing timing information');
    }

    const remoteTimeStart = serverResult.t;

    // Remove the actual service time on the server to leave only the travel roundtrip time (latency)
    const roundtrip = clientTimeEnd - clientTimeStart - serverResult.service;

    // Our local clock should predict what is the timestamp on the server at this moment,
    // so it should account for half the roundtrip it took to get the server start timestamp
    const goalTime = remoteTimeStart - Math.floor(roundtrip / 2);
    const clockOffset = Math.floor(goalTime - clientTimeStart);

    return clockOffset;
}
