// FNV1a hash function,
// fast but not very great at anything.

type u32 = number;

export default function fnv1a(str: string | null): u32 {
    let h = 0x811c9dc5 >>> 0;

    if (str) {
        for (let i = 0, l = str.length; i < l; i++) {
            h = (h ^ str.charCodeAt(i)) >>> 0;

            const incr = (h << 1) + (h << 4) + (h << 7) + (h << 8) + (h << 24);

            h = (h + incr) >>> 0;
        }
    }

    return h;
}
