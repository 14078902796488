import * as d from '@play-co/js-codecs/decoders';

/** SB expects JSON-compatible (but structurally different) JS objects, where properties with an `undefined` value are removed from the structure. */
const prunedRecordDecoder = <A>(decoders: { [key in keyof A]: d.Decoder<A[key]> }): d.Decoder<A> =>
    d.record(decoders).map((value) => {
        for (const key in value) {
            if (value[key] === undefined) {
                delete value[key];
            }
        }
        return value;
    });

export { prunedRecordDecoder as prunedRecord };
