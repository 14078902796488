import fastCopy from 'fast-copy';
import fastDeepEqual from 'fast-deep-equal';
import { Namespaced, UnionToIntersection } from './TypeUtils';

export function copyModifications<T>(oldState: T, newState: T): T {
    if (typeof newState === 'object' && Array.isArray(newState)) {
        if (typeof oldState !== 'object' || !Array.isArray(oldState)) {
            return deepCopy(newState);
        }

        let change = false;
        const newArr = [...newState];

        for (let i = 0; i < newState.length; i++) {
            const newEl = copyModifications(oldState[i], newState[i]);
            if (newEl !== oldState[i]) {
                change = true;
            }
            newArr[i] = newEl;
        }

        if (newState.length !== oldState.length) {
            change = true;
        }

        return change ? (newArr as any) : oldState;
    }

    if (typeof newState === 'object' && newState !== null) {
        if (typeof oldState !== 'object' || oldState === null) {
            return deepCopy(newState);
        }

        let change = false;
        const newObj = { ...newState };

        for (const key in newState) {
            const newEl = copyModifications(oldState[key], newState[key]);
            if (newEl !== oldState[key]) {
                change = true;
            }
            newObj[key] = newEl;
        }

        // Check for deleted properties.
        for (const key in oldState) {
            if (!(key in newState)) {
                change = true;
                break;
            }
        }

        return change ? newObj : oldState;
    }

    // Primitive types
    if (newState !== oldState) {
        return newState;
    } else {
        return oldState;
    }
}

export function deepCopy<T>(obj: T): T {
    return fastCopy(obj);
}

export function deepEquals<T>(x: T, y: T): boolean {
    return fastDeepEqual(x, y);
}

export function getNamespacedValue<T>(obj: Namespaced<T>, path: string): T | undefined {
    const pathParts = path.split('.');

    if (pathParts.length > 2) {
        throw Error('Only 1 level of namespacing is supported');
    }

    const [namespace, key] = pathParts.length === 2 ? pathParts : [undefined, path];

    const namespaceObj = namespace ? obj[namespace] : obj;

    return (namespaceObj as Record<string, T> | undefined)?.[key];
}

export function stripFields(obj: any, fields: string[]) {
    if (typeof obj !== 'object') {
        return obj;
    }

    const res = { ...obj };
    for (const key in res) {
        if (fields.includes(key)) {
            res[key] = '<stripped>';
        } else if (typeof res[key] === 'object') {
            res[key] = stripFields(res[key], fields);
        }
    }

    return res;
}

export function mapObject<T extends { [key: string]: any }, V>(
    source: T | null | undefined,
    fn: (key: keyof T & string, value: T[keyof T]) => V,
) {
    const result: { [key in keyof T]: V } = {} as any;
    for (const key in source) {
        result[key] = fn(key, source[key]);
    }
    return result;
}

const isObject = (obj: any) => {
    if (typeof obj === 'object' && obj !== null) {
        if (typeof Object.getPrototypeOf === 'function') {
            const prototype = Object.getPrototypeOf(obj);
            return prototype === Object.prototype || prototype === null;
        }

        return Object.prototype.toString.call(obj) === '[object Object]';
    }

    return false;
};

export function deepMerge<T extends { [x: string]: any }[]>(...objects: T): UnionToIntersection<T[number]> {
    return objects.reduce((result, current) => {
        Object.keys(current).forEach((key) => {
            if (isObject(result[key]) && isObject(current[key])) {
                result[key] = deepMerge(result[key], current[key]);
            } else {
                result[key] = current[key];
            }
        });

        return result;
    }, {}) as any;
}

export function deepMergeWithArray<T extends { [x: string]: any }[]>(...objects: T): UnionToIntersection<T[number]> {
    return objects.reduce((result, current) => {
        Object.keys(current).forEach((key) => {
            if (isObject(result[key]) && isObject(current[key])) {
                result[key] = deepMergeWithArray(result[key], current[key]);
            } else if (Array.isArray(result[key]) && Array.isArray(current[key])) {
                result[key] = result[key].concat(current[key]);
            } else {
                result[key] = current[key];
            }
        });

        return result;
    }, {}) as any;
}
