import { APIMetainfo } from '../api/ReplicantAPI';
import { ABTestChangeEvent } from '../common/LoginInfo';
import { calculateAssignmentChanges, isPlaceboTestId, ReplicantABTests } from '../common/ReplicantABTests';
import type { ABTestsDynamicConfig } from '../db/DB';
import { Replicant, ReplicantConfig } from '../ReplicantConfig';
import { ABTestBucket, pickABTestBucket, Ruleset } from '../ReplicantRuleset';
import { deepCopy, deepEquals, mapObject } from '../utils/ObjUtils';
import { Immutable } from '../utils/TypeUtils';

type OnChangedHandler = (changeEvents: ABTestChangeEvent[]) => void;

// Public interface.
export interface ClientReplicantABTestsManager<T extends Replicant> {
    /** @returns The current user's A/B test bucket assignment for `testId`. Returns `undefined` if the test is not assigned or does not exist. */
    getBucketID<TABTestID extends keyof T['ruleset']['abTests'] & string>(
        testId: TABTestID,
    ): ABTestBucket<NonNullable<T['ruleset']['abTests']>, TABTestID> | undefined;
    getABTests(): Partial<Record<string, string>>;
    getABTag(): string;

    /**
     * Get another user's A/B test bucket assignment. Returns `undefined` if the test does not exist.
     *
     * Note that the return value does not account for manual assignment or [user lists](https://docs.dev.gc-internal.net/replicant/ab-tests/#user-lists).
     *
     * Use `getBucketID` instead when querying the current user's bucket assignments.
     */
    getUserBucketID<TABTestID extends keyof T['ruleset']['abTests'] & string>(
        testId: TABTestID,
        userId: string,
    ): ABTestBucket<NonNullable<T['ruleset']['abTests']>, TABTestID> | undefined;

    setOnChangedHandler(handler: OnChangedHandler): void;
}

export class ClientReplicantABTests<T extends Replicant> implements ClientReplicantABTestsManager<T> {
    clientActionsApi: ReplicantABTests;

    private assignmentsCache: NonNullable<APIMetainfo['abTestAssignments']>;
    private changeEvents: ABTestChangeEvent[];
    private onABTestAssignmentsChanged: OnChangedHandler | undefined;

    constructor(
        private opts: {
            config: ReplicantConfig<T>;
            dynamicConfig: ABTestsDynamicConfig;
            metainfo: Immutable<APIMetainfo>;
            changeEvents: ABTestChangeEvent[];
        },
    ) {
        this.clientActionsApi = new ReplicantABTests({
            abTests: opts.config.ruleset?.abTests ?? {},
            dynamicConfig: opts.dynamicConfig,
        });
        this.assignmentsCache = deepCopy(opts.metainfo.abTestAssignments ?? {});
        this.changeEvents = this.opts.changeEvents.slice();
    }

    getBucketID<TABTestID extends keyof T['ruleset']['abTests'] & string>(
        testId: TABTestID,
    ): ABTestBucket<NonNullable<T['ruleset']['abTests']>, TABTestID> | undefined {
        return this.clientActionsApi.getBucketID(this.opts.metainfo, testId) as
            | ABTestBucket<NonNullable<T['ruleset']['abTests']>, TABTestID>
            | undefined;
    }

    getABTests(): Partial<Record<string, string>> {
        return mapObject(this.assignmentsCache, (key, value) => value?.bucketId);
    }

    getABTag(): string {
        return (
            // an the AB tag contains active tests
            Object.keys(this.assignmentsCache)
                .filter((testId) => this.includeInABTag(testId))

                // Sorted by test ID so it's consistent between users
                .sort()
                .map((testId) => this.getTestTag(testId))
                .join(',')
        );
    }

    getUserBucketID<TABTestID extends keyof T['ruleset']['abTests'] & string>(
        testId: TABTestID,
        userId: string,
    ): ABTestBucket<NonNullable<T['ruleset']['abTests']>, TABTestID> | undefined {
        const testsConfig: Ruleset['abTests'] = this.opts.config.ruleset?.abTests;
        if (!testsConfig?.[testId]) {
            return undefined;
        }

        return pickABTestBucket(userId, testId, testsConfig) as ABTestBucket<
            NonNullable<T['ruleset']['abTests']>,
            TABTestID
        >;
    }

    /**
     * @param handler A function that will be called whenever AB tests are manually assigned from the client.
     * Called after an action completes. If multiple tests are assigned in the same action, the handler will be called once.
     * Automatically called on login if AB test assignments have changed.
     */
    setOnChangedHandler(handler: OnChangedHandler): void {
        this.onABTestAssignmentsChanged = handler;

        // In case there are old events, process them now.
        this.processChangeEvents();
    }

    handleReplicantActionCompleted() {
        const assignments = this.opts.metainfo.abTestAssignments ?? {};
        if (deepEquals(this.assignmentsCache, assignments)) {
            return;
        }

        // Queue the event for when there's a callback.
        this.changeEvents.push(
            ...calculateAssignmentChanges({ newAssignments: assignments, oldAssignments: this.assignmentsCache }),
        );

        // Update the cache so the user can access it in the callback.
        this.assignmentsCache = deepCopy(assignments);

        // In case there's already a callback, process the event.
        this.processChangeEvents();
    }

    private processChangeEvents() {
        if (!this.onABTestAssignmentsChanged || !this.changeEvents.length) {
            return;
        }

        const changeEvents = this.changeEvents;
        this.changeEvents = [];

        this.onABTestAssignmentsChanged(changeEvents);
    }

    private includeInABTag(testId: string) {
        const ruleset: Ruleset['abTests'] = this.opts.config.ruleset?.abTests;
        if (!ruleset?.[testId]) {
            return false;
        }

        return !isPlaceboTestId(testId);
    }

    private getTestTag(testId: string) {
        const ruleset: NonNullable<Ruleset['abTests']> = this.opts.config.ruleset!.abTests!;

        const bucketId = this.getBucketID(testId as keyof T['ruleset']['abTests'] & string);
        const bucketIndex = ruleset[testId]!.buckets.findIndex((bucket) => bucket.id === bucketId);

        // A test's tag contains part of the test id for readability.
        return `${testId.substring(0, 4)}-${bucketIndex}`;
    }
}
