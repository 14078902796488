import * as jsonPatch from 'fast-json-patch';
import type { default as DB } from './db/DB';
import { ReplicantError } from './Errors';
import { revertMutationIfThrows } from './server/utils/JSONPatchUtils';
import { systemStateFieldNames } from './systemStateFields';
import { generateMessageId } from './utils/MessageUtils';
import { deepCopy } from './utils/ObjUtils';

export type EventHandlerMessageEvent =
    | { type: 'onGameEnd' }
    | { type: 'onWebhook' }
    | { type: 'scheduledAction'; scheduledActionName: string };

/**
 * A message written in case of player state mutation in an event handler (e.g., a chatbot webhook event handler or a scheduled action).
 *
 * Direct player state writes in event handlers can cause race conditions with active player sessions. Thus we instead track
 * state-mutating event handler invocations in messages which eventually get reduced to player states similarly to regular Replicant messages.
 *
 * @see https://playco.atlassian.net/wiki/spaces/CTD/pages/2365095941/Replicant+mutable+player+state+in+event+handlers
 */
export type EventHandlerMessage = {
    event: EventHandlerMessageEvent;
    id: string;

    /** ID of the last message applied to user state before creating this event handler message. */
    lastAppliedMessageId?: string;

    metainfoDiff: jsonPatch.Operation[];
    stateDiff: jsonPatch.Operation[];
    timestamp: number;
};

/** Resolve `operation` and return a lists of mutations performed on each item in `targetObjs`. */
export async function captureDiff(
    targetObjs: Object[],
    operation: () => Promise<void>,
): Promise<jsonPatch.Operation[][]> {
    const originalObjs = targetObjs.map(deepCopy);

    await operation();

    const diffs: jsonPatch.Operation[][] = [];

    for (let i = 0; i < targetObjs.length; i++) {
        const targetObj = targetObjs[i]!;
        const originalObj = originalObjs[i]!;

        const diffItems = jsonPatch.compare(originalObj, targetObj, false);

        // Transform array push operations to use `/-` paths instead of `/<index>` paths.
        // This ensures that consecutive array pushes maintain their ordering in the resulting diff (see https://github.com/Starcounter-Jack/JSON-Patch/issues/78).
        for (const diff of diffItems) {
            const arrayIndexSuffixRegex = /\/\d+$/;

            if (diff.op === 'add' && arrayIndexSuffixRegex.test(diff.path)) {
                const pathComponents = diff.path.split('/');
                const targetIndex = Number(pathComponents.pop());
                const targetArrayPath = pathComponents.join('/');
                const targetArray = jsonPatch.getValueByPointer(originalObj, targetArrayPath);

                const pushingToLastIndex =
                    Array.isArray(targetArray) &&
                    Number.isSafeInteger(targetIndex) &&
                    targetArray.length === targetIndex;

                if (pushingToLastIndex) {
                    diff.path = targetArrayPath + '/-';
                }
            }

            jsonPatch.applyOperation(originalObj, deepCopy(diff));
        }

        diffs.push(diffItems);
    }

    return diffs;
}

/** Resolve event handler, and if the event handler mutates player metainfo or state write an event handler message item to DB. */
export async function resolveEventHandlerAndWriteMessage(opts: {
    counter?: number;
    db: DB<any>;
    entry: { lastAppliedMessageId?: string; state: { [key: string]: unknown }; metainfo: { [key: string]: unknown } };
    event: EventHandlerMessageEvent;
    eventHandler: () => Promise<void>;
    now: number;
    userId: string;
}): Promise<void> {
    const [stateAndSystemStateFieldDiff, metainfoDiff] = await captureDiff(
        [opts.entry.state, opts.entry.metainfo],
        opts.eventHandler,
    );

    // Omit system state field mutations from state diff:
    const mutatesSystemStateField = (op: jsonPatch.Operation): boolean =>
        systemStateFieldNames.some((name) => op.path.startsWith(`/${name}/`));

    const stateDiff = (stateAndSystemStateFieldDiff ?? []).filter((op) => !mutatesSystemStateField(op));

    if (!metainfoDiff || (stateDiff.length === 0 && metainfoDiff.length === 0)) {
        return;
    }

    const eventHandlerMessage: EventHandlerMessage = {
        event: opts.event,
        id: generateMessageId(opts.now, opts.counter || 0),
        lastAppliedMessageId: opts.entry.lastAppliedMessageId,
        metainfoDiff,
        stateDiff,
        timestamp: opts.now,
    };

    await opts.db.writeEventHandlerMessage(opts.userId, eventHandlerMessage);
}

export function applyEventHandlerMessage(
    entry: { metainfo: { [key: string]: unknown }; state: { [key: string]: unknown } },
    message: EventHandlerMessage,
): void {
    try {
        revertMutationIfThrows(entry, () => {
            jsonPatch.applyPatch(entry.metainfo, deepCopy(message.metainfoDiff), true);
            jsonPatch.applyPatch(entry.state, deepCopy(message.stateDiff), true);
        });
    } catch (error: any) {
        const errorMsg = 'Cannot apply event handler message diff: ' + error.message?.split('\n')[0];
        const extras = { index: error.index, message, operation: error.operation, tree: error.tree };

        throw new ReplicantError(errorMsg, 'replication_error', 'inapplicable_diff', 'error', extras);
    }
}
