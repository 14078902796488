import type { Actions } from './ReplicantActions';
import type { AsyncGetters } from './ReplicantAsyncGetters';
import type { ChatbotAssets } from './ReplicantChatbot';
import type { ComputedProperties } from './ReplicantComputedProperties';
import type { PartialReplicant, Replicant, ReplicantConfig } from './ReplicantConfig';
import type { Messages } from './ReplicantMessages';
import type { EmptyRuleset, Ruleset } from './ReplicantRuleset';
import type { ScheduledActions } from './ReplicantScheduledActions';
import type { SharedStates } from './ReplicantSharedStates';
import SB from './SchemaBuilder';
import { ReplicantAsyncActionAPI, ReplicantSyncActionAPI } from './api/ReplicantAPI';
import { UnionToIntersection } from './utils/TypeUtils';

/**
 * A configuration module that extends `ReplicantConfig`.
 *
 * @see https://docs.dev.gc-internal.net/replicant/ConfigurationModules/
 *
 * @experimental
 */
export type ReplicantModule<
    TName extends keyof TState = string,
    TState extends Record<string, any> = any,
    TActions extends Actions<
        PartialReplicant<{
            messages: TMessages;
            computedProperties: TComputedProperties;
            scheduledActions: TScheduledActions;
            asyncGetters: TAsyncGetters;
            chatbotAssets: TChatbotAssets;
            ruleset: TRuleset;
            sharedStates: TSharedStates;
        }>
    > = {},
    TMessages extends Messages<TState, TRuleset, TSharedStates> = {},
    TComputedProperties extends ComputedProperties = {},
    TScheduledActions extends ScheduledActions<Replicant<TState>> = {},
    TAsyncGetters extends AsyncGetters<TState, TRuleset, TComputedProperties, TSharedStates> = {},
    TChatbotAssets extends ChatbotAssets = {},
    TRuleset extends Ruleset = EmptyRuleset,
    TSharedStates extends SharedStates = {},
> = {
    config: {
        stateSchema: SB.Schema<TState>;
        actions: TActions;
        messages: TMessages;
        computedProperties: TComputedProperties;
        scheduledActions: TScheduledActions;
        asyncGetters: TAsyncGetters;
        chatbotAssets: TChatbotAssets;
        onLoginAction: string | undefined;
        ruleset: TRuleset;
        sharedStates: TSharedStates;
    };

    name: TName;

    /** Access the module API object within Replicant configuration, e.g., in an action. */
    api(asyncActionApi: ReplicantAsyncActionAPI<any>): ReplicantAsyncActionAPI<
        PartialReplicant<{
            messages: TMessages;
            computedProperties: TComputedProperties;
            scheduledActions: TScheduledActions;
            asyncGetters: TAsyncGetters;
            chatbotAssets: TChatbotAssets;
            ruleset: TRuleset;
            sharedStates: TSharedStates;
        }>
    >;
    api(syncActionApi: ReplicantSyncActionAPI<any>): ReplicantSyncActionAPI<
        PartialReplicant<{
            messages: TMessages;
            computedProperties: TComputedProperties;
            scheduledActions: TScheduledActions;
            asyncGetters: TAsyncGetters;
            chatbotAssets: TChatbotAssets;
            ruleset: TRuleset;
            sharedStates: TSharedStates;
        }>
    >;

    /**
     * Access module state within Replicant configuration, e.g., in an action.
     *
     * @param userState Replicant user state.
     */
    state(userState: unknown): TState[TName];
};

export type ReplicantModulesState<T extends ReplicantModule[]> = T extends never[]
    ? {}
    : UnionToIntersection<SB.ExtractType<T[number]['config']['stateSchema']>>;

export type ReplicantModulesActions<T extends ReplicantModule[]> = T extends never[]
    ? {}
    : UnionToIntersection<T[number]['config']['actions']>;

export type ReplicantModulesMessages<T extends ReplicantModule[]> = T extends never[]
    ? {}
    : UnionToIntersection<T[number]['config']['messages']>;

export type ReplicantModulesAsyncGetters<T extends ReplicantModule[]> = T extends never[]
    ? {}
    : UnionToIntersection<T[number]['config']['asyncGetters']>;

export type ReplicantWithModules<T extends Replicant, M extends ReplicantModule[]> = {
    actions: T['actions'] & ReplicantModulesActions<M>;
    asyncGetters: T['asyncGetters'] & ReplicantModulesAsyncGetters<M>;
    chatbotAssets: T['chatbotAssets'];
    computedProperties: T['computedProperties'];
    messages: T['messages'] & ReplicantModulesMessages<M>;
    modules: T['modules'];
    onLoginAction: T['onLoginAction'];
    otpTemplateId: T['otpTemplateId'];
    ruleset: T['ruleset'];
    scheduledActions: T['scheduledActions'];
    sharedStates: T['sharedStates'];
    state: T['state'] & ReplicantModulesState<M>;
};

export function mergeModuleConfig<T extends Replicant, M extends ReplicantModule[]>(
    config: ReplicantConfig<T>,
    modules?: M,
): ReplicantConfig<ReplicantWithModules<T, M>> {
    validateModuleNames(config, modules ?? []);

    const allModuleStateSchemas = {} as ReplicantModulesState<M>;
    const allModuleActions = {} as ReplicantModulesActions<M>;
    const allModuleMessages = {} as ReplicantModulesMessages<M>;
    const allModuleAsyncGetters = {} as ReplicantModulesAsyncGetters<M>;

    for (const { config, name } of modules ?? []) {
        if (!config.stateSchema.isObjectSchema()) {
            throw Error(`Invalid module configuration: \`modules.${name}.stateSchema\` is not an object schema`);
        }

        Object.assign(allModuleStateSchemas, config.stateSchema.getFullSchema());

        Object.assign(allModuleActions, config.actions);

        Object.assign(allModuleMessages, config.messages);

        Object.assign(allModuleAsyncGetters, config.asyncGetters);
    }

    if (!config.stateSchema.isObjectSchema()) {
        throw Error('Invalid Replicant configuration: `stateSchema` is not an object schema');
    }

    return {
        ...config,
        actions: { ...config.actions, ...allModuleActions },
        messages: { ...config.messages, ...allModuleMessages },
        asyncGetters: { ...config.asyncGetters, ...allModuleAsyncGetters },
        stateSchema: SB.object({ ...config.stateSchema.getFullSchema(), ...allModuleStateSchemas }),
    };
}

function validateModuleNames(config: ReplicantConfig<any>, modules: { name: string }[]): void {
    const uniqueModuleNames = new Set<string>();

    const namespacedProperties: (keyof ReplicantConfig<any>)[] = [
        'actions',
        'messages',
        'asyncGetters',
        'computedProperties',
        'scheduledActions',
        'sharedStates',
    ];

    for (const { name } of modules) {
        if (uniqueModuleNames.has(name)) {
            throw Error(`Duplicate module name in Replicant configuration: \`${name}\`. Try renaming the module.`);
        }

        uniqueModuleNames.add(name);

        if (!config.stateSchema.isObjectSchema()) {
            throw Error(`Unexpected stateSchema type: ${config.stateSchema._type}`);
        }

        if (name in config.stateSchema.getFullSchema()) {
            throw Error(`Module name \`${name}\` conflicts with \`stateSchema.${name}\`. Try renaming the module.`);
        }

        for (const prop of namespacedProperties) {
            if (config[prop] && name in config[prop]) {
                throw Error(`Module name \`${name}\` conflicts with \`${prop}.${name}\`. Try renaming the module.`);
            }
        }
    }
}

/**
 * Create a configuration module.
 *
 * Pass the return value into `createConfig({ modules: [ ... ]})` to extend Replicant configuration with the module.
 *
 * @see https://docs.dev.gc-internal.net/replicant/ConfigurationModules/
 *
 * @experimental
 */
export function createModule<
    TName extends keyof TState & string,
    TState extends Record<string, any> = {},
    TActions extends Actions<
        PartialReplicant<{
            state: TState;
            messages: TMessages;
            computedProperties: TComputedProperties;
            scheduledActions: TScheduledActions;
            asyncGetters: TAsyncGetters;
            chatbotAssets: TChatbotAssets;
            ruleset: TRuleset;
            sharedStates: TSharedStates;
        }>
    > = {},
    TMessages extends Messages<TState, TRuleset, TSharedStates> = {},
    TComputedProperties extends ComputedProperties = any,
    TScheduledActions extends ScheduledActions = any,
    TAsyncGetters extends AsyncGetters<TState, TRuleset, TComputedProperties, TSharedStates> = {},
    TChatbotAssets extends ChatbotAssets = any,
    TRuleset extends Ruleset = EmptyRuleset,
    TSharedStates extends SharedStates = {},
>(
    name: TName,
    opts: Partial<{
        stateSchema: SB.Schema<TState>;
        actions: TActions;
        messages: TMessages;
        computedProperties: TComputedProperties;
        scheduledActions: TScheduledActions;
        asyncGetters: TAsyncGetters;
        chatbotAssets: TChatbotAssets;

        /**
         * Name of a module action invoked on each login. The action must be nested under a namespace that matches module name.
         *
         * In case of multiple modules with onLogin actions, the actions are invoked in the order of the modules definition in `createConfig({ modules: [...] })`.
         * The main onLogin action defined in `createConfig({ onLoginAction: ... })` is always invoked before module onLogin actions.
         *
         * Module onLogin actions cannot be parameterized: the `onLoginActionArgs` client creation property only applies to the main configuration onLogin action.
         */
        onLoginAction: (keyof TActions[TName] & string) | undefined;

        ruleset: TRuleset;
        sharedStates: TSharedStates;
    }>,
): ReplicantModule<
    TName,
    TState,
    TActions,
    TMessages,
    TComputedProperties,
    TScheduledActions,
    TAsyncGetters,
    TChatbotAssets,
    TRuleset,
    TSharedStates
> {
    const moduleName = name.toString();

    const defaultOpts = {
        actions: {} as TActions,
        asyncGetters: {} as TAsyncGetters,
        chatbotAssets: {} as TChatbotAssets,
        computedProperties: {} as TComputedProperties,
        messages: {} as TMessages,
        onLoginAction: undefined,
        ruleset: { abTests: {} } as TRuleset,
        scheduledActions: {} as TScheduledActions,
        sharedStates: {} as TSharedStates,
        stateSchema: SB.object({}) as unknown as SB.Schema<TState>,
    };

    const config = { ...defaultOpts, ...opts };

    validateModuleConfig(name, config);

    if (!config.stateSchema.isObjectSchema()) {
        throw Error(`Invalid module configuration: \`modules.${moduleName}.stateSchema\` is not an object schema`);
    }

    const expectedUserStateSchema = config.stateSchema.additionalProperties();

    return {
        config,

        name,

        api(inputApi) {
            for (const key in config.messages) {
                if (!inputApi.postMessage[key]) {
                    throw Error(
                        `Module \`${moduleName}\` not found in messages configuration. Configure module in \`createConfig({ modules: ... })\``,
                    );
                }
            }

            if (isAsyncActionApi(inputApi)) {
                for (const key in config.asyncGetters) {
                    if (!inputApi.asyncGetters[key]) {
                        throw Error(
                            `Module \`${moduleName}\` not found in async getters configuration. Configure module in \`createConfig({ modules: ... })\``,
                        );
                    }
                }
            }

            return inputApi as any;
        },

        state(userState: unknown): TState[TName] {
            if (!expectedUserStateSchema.isValid(userState)) {
                throw Error(
                    `Module \`${moduleName}\` state not found in user state. Configure module in \`createConfig({ modules: ... })\``,
                );
            }

            return userState[name];
        },
    };
}

function isAsyncActionApi(
    api: ReplicantSyncActionAPI<any> | ReplicantAsyncActionAPI<any>,
): api is ReplicantAsyncActionAPI<any> {
    return api.isAsync;
}

function validateModuleConfig(name: string, config: ReplicantModule['config']): void {
    const actions = config.actions[name as keyof (typeof config)['actions']] ?? {};
    const errorPrefix = `Invalid configuration in module \`${name}\`: `;

    if (config.onLoginAction && !actions[config.onLoginAction]) {
        throw Error(
            `${errorPrefix}\`onLoginAction: ${config.onLoginAction}\` points to non-existent action. Define the action in \`actions.${name}.${config.onLoginAction}\`.`,
        );
    }
}
