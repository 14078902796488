import { ReplicantError } from '../Errors';

export function fixStackTrace<T>(error: T): T {
    if (error instanceof Error) {
        Error.captureStackTrace(error);
    }

    return error;
}

export function fixStackTraceAndRethrow(error: Error): never {
    throw fixStackTrace(error);
}

const SERIALIZED_KEY = '__serialized__';
const SERIALIZED_ERROR_ID = '__error__';

/** @returns `input` as a JSON-compatible object, or as is if not an Error object. */
export function serializeError(input: any) {
    if (input instanceof Error) {
        return {
            ...input,
            name: input.name,
            message: input.message,
            stack: input.stack,
            [SERIALIZED_KEY]: SERIALIZED_ERROR_ID,
        };
    }

    return input;
}

/** @returns `input` as an Error object, or as is if not a serialized error object. */
export function deserializeError(input: any) {
    if (typeof input === 'object' && input !== null && input[SERIALIZED_KEY] === SERIALIZED_ERROR_ID) {
        const error = input.name === 'ReplicantError' ? new ReplicantError(input.message, input.code) : Error();

        for (const key of Object.keys(input)) {
            if (key !== SERIALIZED_KEY) {
                error[key as keyof Error] = input[key];
            }
        }

        return error;
    }

    return input;
}

/**
 * The output of this function is intended to be as close to JS as possible, while still being useful to determine
 * structure and values.
 */
export function encodeJS(value: unknown, spaces = -1): string {
    spaces = Math.max(spaces, -1);
    return _encode(value, 0);

    function _encode(value: unknown, level: number): string {
        switch (typeof value) {
            case 'object':
                if (value === null) {
                    return 'null';
                } else if (Array.isArray(value)) {
                    return _encodeArray(value, level);
                } else if (!value.constructor || value.constructor.name === 'Object') {
                    return _encodeObject(value, level);
                } else if (value.constructor.name === 'RegExp') {
                    // eslint-disable-next-line @typescript-eslint/no-base-to-string
                    return value.toString();
                } else if (value.constructor.name) {
                    return '[object ' + value.constructor.name + ']';
                } else {
                    return '[anonymous object]';
                }
            case 'undefined':
                return 'undefined';
            case 'number':
                if (Number.isNaN(value)) {
                    return 'NaN';
                } else {
                    switch (value) {
                        case Infinity:
                            return 'Infinity';
                        case -Infinity:
                            return '-Infinity';
                        default:
                            return JSON.stringify(value);
                    }
                }
            case 'bigint':
                return value.toString() + 'n';
            case 'function':
                if (value.name) {
                    return '[function ' + value.name + ']';
                } else {
                    return '[anonymous function]';
                }
            case 'symbol':
                return value.toString();
            default:
                return JSON.stringify(value);
        }
    }

    function _encodeObject<T extends object>(value: T, level: number): string {
        const parts = [];
        for (const key in value) {
            // eslint-disable-next-line no-prototype-builtins
            if (value.hasOwnProperty(key)) {
                parts.push(JSON.stringify(key) + ': ' + _encode(value[key], level + 1));
            }
        }
        return _formatObject(parts, '{', '}', level);
    }

    function _encodeArray(value: unknown[], level: number): string {
        const parts = [];
        for (const elem of value) {
            parts.push(_encode(elem, level + 1));
        }
        return _formatObject(parts, '[', ']', level);
    }

    function _formatObject(parts: string[], leftDelim: string, rightDelim: string, level: number) {
        if (parts.length === 0) {
            return leftDelim + rightDelim;
        } else if (spaces === -1) {
            return [leftDelim, parts.join(', '), rightDelim].join(' ');
        } else {
            return [
                leftDelim,
                parts.map((part) => ' '.repeat(spaces * (level + 1)) + part).join(',\n'),
                ' '.repeat(spaces * level) + rightDelim,
            ].join('\n');
        }
    }
}
