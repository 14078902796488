import * as jsonPatch from 'fast-json-patch';

/** Resolve `operation` and in case of error, revert any mutations performed on `obj` and re-throw error.  */
export function revertMutationIfThrows(obj: { [key: string]: unknown }, operation: () => void): void {
    const originalObjString = JSON.stringify(obj);

    try {
        operation();
    } catch (error) {
        // Why not just assign `original.obj = JSON.parse(originalObjString)` instead of using JSON-Patch?
        // To avoid breaking any references that might be attached to `original.obj`, for example VirtualState's state change listeners.
        const originalState = JSON.parse(originalObjString);
        const revertingPatch = jsonPatch.compare(obj, originalState, true);
        jsonPatch.applyPatch(obj, revertingPatch, true);

        throw error;
    }
}

/** @returns The JSON-Patch diff of `observedObject` resulting from performing `operation`. */
export function withJsonPatchDiff(observedObject: Object, operation: () => unknown): jsonPatch.Operation[] {
    const observer = jsonPatch.observe<any>(observedObject);

    operation();

    const diff = jsonPatch.generate(observer);

    observer.unobserve();

    return diff;
}
