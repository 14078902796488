import type { SocialGraphInteraction } from '../ReplicantSocialGraph';
import ReplicantHttpClient from './ReplicantHttpClient';

export type NewSocialGraphInteraction = Omit<SocialGraphInteraction, 'timestamp'> & {
    /**
     * Interaction time as a millisecond-level timestamp.
     *
     * @default current `Date.now()` value
     */
    timestamp?: number;
};

export class ClientReplicantSocialGraph {
    private calledUpdateSelf = false;

    constructor(
        private httpClient: ReplicantHttpClient,
        private now: () => number,
    ) {}

    /**
     * Update player's node in the social graph. Call this method once at game start.
     *
     * @param nonPlayerFriendIds IDs of player's non-playing friends, as reported by the platform.
     * @param meta Metadata attached to player's node in the social graph, for example `{ level: 10, age: 28 }`.
     */
    async updateSelf(nonPlayerFriendIds: string[], meta: { [key: string]: unknown }): Promise<void> {
        this.calledUpdateSelf = true;

        await this.httpClient.doPostSocialGraphUpdateSelf({ nonPlayerFriendIds, meta });
    }

    /**
     * Track social interactions, such as sending an invite to a non-playing friend.
     *
     * @throws if called before calling `updateSelf`.
     */
    async trackInteractions(interactions: NewSocialGraphInteraction[]): Promise<void> {
        if (!this.calledUpdateSelf) {
            throw Error('socialGraph.updateSelf must be called before tracking interactions');
        }

        // Attach current timestamp to interactions if timestamp is not already defined:
        const now = this.now();
        const interactionsWithTimestamp = interactions.map((interaction) => ({
            ...interaction,
            timestamp: interaction.timestamp || now,
        }));

        await this.httpClient.doPostSocialGraphTrackInteractions({ interactions: interactionsWithTimestamp });
    }
}
