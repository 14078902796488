import { ReplicantABTests } from '../../common/ReplicantABTests';
import { ChatbotMetainfo, UserSharedStateItems } from '../../db/DB';
import { Replicant } from '../../ReplicantConfig';
import { Message } from '../../ReplicantMessages';
import { deepCopy } from '../../utils/ObjUtils';
import { APIMetainfo } from '../ReplicantAPI';
import { CacheableReplicantSyncActionAPI, createReplicantAPIClientUtil } from './ReplicantClientImpl';
import { ActionAnalyticsCallback } from '../../common/Analytics';

export type PostedMessages = [string, Message][];

export class SyncAPICache<T extends Replicant> {
    private api: CacheableReplicantSyncActionAPI<T>;
    private postedMessages: PostedMessages = [];
    // Temp meta info is because the api metainfo should only be
    // updated once the action is complete so for every api call
    // a deep copy of the current meta info is made
    private tempMetaInfo?: APIMetainfo;
    private tempSharedStates?: UserSharedStateItems<T['sharedStates']>;
    private invokeTime = 0;
    private analyticsCallback: ActionAnalyticsCallback = () => {};

    constructor(params: {
        id: () => string;
        sessionId: () => string;
        apiMetaInfo: () => APIMetainfo;
        userSharedStates: () => UserSharedStateItems<T['sharedStates']>;
        chatbotMetainfo: () => ChatbotMetainfo;
        messages: () => T['messages'];
        scheduledActions: () => T['scheduledActions'];
        sharedStates: () => T['sharedStates'] | undefined;
        ruleset: () => T['ruleset'] | undefined;
        userAssetsBaseUrl: () => string;
        abTestsApiAccess: () => ReplicantABTests;
    }) {
        this.api = createReplicantAPIClientUtil<T>({
            id: params.id,
            sessionId: params.sessionId,
            apiMetainfo: () => {
                return (this.tempMetaInfo = this.tempMetaInfo ?? deepCopy(params.apiMetaInfo()));
            },
            userSharedStates: () => {
                return (this.tempSharedStates = this.tempSharedStates ?? deepCopy(params.userSharedStates()));
            },
            chatbotMetainfo: params.chatbotMetainfo(),
            invokeTime: () => this.invokeTime,
            messages: params.messages(),
            scheduledActions: params.scheduledActions(),
            sharedStates: params.sharedStates(),
            onMessagePosted: (...args) => this.postedMessages.push(args),
            userAssetsBaseUrl: params.userAssetsBaseUrl,
            abTestsApiAccess: params.abTestsApiAccess,
            getAnalyticsCallback: () => this.analyticsCallback,
        });
    }

    getAPI(invokeTime: number): CacheableReplicantSyncActionAPI<T> {
        this.postedMessages = [];
        this.tempMetaInfo = undefined;
        this.tempSharedStates = undefined;
        if (this.api.meta?.hasUsedDateNow) {
            this.api.meta.hasUsedDateNow = false;
        }
        this.invokeTime = invokeTime;
        return this.api;
    }

    updateMetaInfo(metaInfo: APIMetainfo) {
        if (this.tempMetaInfo) {
            for (const key in metaInfo) {
                (metaInfo as any)[key] = (this.tempMetaInfo as any)[key];
            }
        }
    }

    updateUserSharedStates(userSharedStates: UserSharedStateItems<T['sharedStates']>) {
        for (const key in this.tempSharedStates) {
            (userSharedStates as any)[key] = (this.tempSharedStates as any)[key];
        }
    }

    getPostedMessages(): PostedMessages {
        return this.postedMessages;
    }

    getInvokeTime(): number {
        return this.invokeTime;
    }

    setAnalyticsCallback(callback: ActionAnalyticsCallback) {
        this.analyticsCallback = callback;
    }
}
