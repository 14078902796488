import { duration } from '../utils/Utils';

export const ANONYMOUS_WEB_PLAYER_ID_PREFIX = 'web_';
export const HEARTBEAT_ACTION = '_heartbeat';
export const APPLY_MESSAGES_ACTION = '_applyMessages';

/** Report a Sentry warning if the argument size of an individual action, scheduled action, message or shared state message exceeds this limit. */
export const ARGS_TOO_LARGE_WARNING_LIMIT_BYTES = 100_000;

export const DEFAULT_MAX_RECENT_MESSAGES_TIME = duration({ seconds: 30 });
export const MAX_CLIENT_SYNC_CLOCK_OFFSET = duration({ seconds: 90 });
export const MAX_LARGE_CLIENT_SYNC_CLOCK_OFFSET = duration({ minutes: 30 });
export const MAX_REQUEST_DURATION = duration({ seconds: 30 });

/** Key prefix to the messengerId->playerId mapping pairs in internal KV store. */
export const KV_MESSENGER_ID_PREFIX = 'psid_';
