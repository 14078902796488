export default class ReplicantMigrator {
    private migrations: ((state: any) => void)[] = [];

    addMigration(version: number, migration: (oldState: any) => void) {
        if (version !== this.migrations.length + 1) {
            throw new Error('Migration version ' + version + ' not consecutive!');
        }
        this.migrations.push(migration);
    }

    getLatestVersion() {
        return this.migrations.length + 1;
    }

    applyMigration(state: any, version: number) {
        // We're migrating to version `version`
        const migrationId = version - 1;
        if (migrationId < 0 || migrationId >= this.migrations.length) {
            throw new Error('Invalid migrationIndex: ' + migrationId + ' when trying to migrate to version ' + version);
        }

        this.migrations[migrationId]!(state);
    }
}
